import React, { useState, useRef } from 'react';
import { vocabulary } from '../../../services/vocabulary';

export default function FileUpload({ handleFileUpload, lang }) {
  const [isDragging, setIsDragging] = useState(false);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
    handleFileUpload(droppedFile);
  };

  const handleFileSelect = () => {
    setFile(fileInputRef.current.click());
  };

  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    handleFileUpload(selectedFile);
  };

  return (
    <div
      className="upload-drug-n-drop-div"
      style={{
        borderColor: isDragging ? '#007bff' : '#ccc',
      }}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {isDragging ? (
        <p>{vocabulary.dropFileHere[lang]}</p>
      ) : (
        <p>{vocabulary.dragNDropFileHere[lang]}</p>
      )}
      <input
        type="file"
        onChange={handleFileInputChange}
        style={{ display: 'none' }}
        ref={fileInputRef}
      />
      <button onClick={handleFileSelect} className="button-download">
        {vocabulary.selectFile[lang]}
      </button>
      {file && (
        <p>
          {vocabulary.selectedFile[lang]}: {file.name}
        </p>
      )}
    </div>
  );
}
