import Layout from '../Components/Layout/Layout';
import { getAllVideos } from '../../api/workFlowApi';
import { useContext, useState } from 'react';
import sortIcon from '../../images/sort-icon.png';
import { useEffect } from 'react';
import VideoCardItem from '../Components/VideosScreen/VideoCardItem';
import NewVideo from '../Components/VideosScreen/NewVideo';
import { useNavigate } from 'react-router-dom';
import RenameVideo from '../Components/VideosScreen/RenameVideo';
import DeleteVideo from '../Components/VideosScreen/DeleteVideo';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import sortDateIcon from '../../images/sort-date.png';
import SearchIcon from '@mui/icons-material/Search';
import ToggleButtons from '../Components/VideosScreen/ToggleButtons';
import VideoListItem from '../Components/VideosScreen/VideoListItem';
import { vocabulary } from '../../services/vocabulary';
import GlobalContext from '../../GlobalContext';
import TechSpec from '../Components/VideosScreen/TechSpec';

export default function VideoScreen() {
  const [videosReceived, setVideosReceived] = useState([]);
  const [sortUp, setSortUp] = useState(true);
  const [showRenameVideo, setShowRenameVideo] = useState(false);
  const [videos, setVideos] = useState([]);
  const [videoSelected, setVideoSelected] = useState('');
  const [showNewVideo, setShowNewVideo] = useState(false);
  const [userWantToDeleteVideo, setUserWantToDeleteVideo] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [startDate, setStartDate] = useState(getFormattedDate("2023-01-01"));
  const [endDate, setEndDate] = useState(getFormattedDateNow());
  const [referredArray, setReferredArray] = useState([]);
  const [listView, setListView] = useState(true);
  const { langSelected, state } = useContext(GlobalContext);
  const [lang, setLang] = useState(0);
  const [showTechSpec, setShowTechSpec] = useState(false);
  const [videoChoosed, setVideoChoosed] = useState("");

  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);

  function getFormattedDate(dateString) {
    const currentDate = new Date(dateString);
    return currentDate.toISOString().split('T')[0];
  }
  function getFormattedDateNow() {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 6);
    return currentDate.toISOString().split('T')[0];
  }


  const handleSetListView = () => {
    setListView(!listView);
  };
  const navigate = useNavigate();

  const handleSortDate = () => {
    let arr = [...videos];

    arr.sort((a, b) => {
      const videoNameA = new Date(b.createdAt);
      const videoNameB = new Date(a.createdAt);
      if (videoNameA < videoNameB) {
        return sortUp ? -1 : 1;
      }
      if (videoNameA > videoNameB) {
        return sortUp ? 1 : -1;
      }
      return 0;
    });
    setSortUp(!sortUp);

   setVideos(arr);
    setReferredArray(arr);
  };

  async function onLoadGet() {
    try {
      let videoList = await getAllVideos();
    
      setVideos(videoList);
      setVideosReceived(videoList);
      setReferredArray(videoList);
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  }

  useEffect(()=>{

onLoadGet()



  },[])

  async function onClickGetShow(
    id,
    blobUrl,
    videoDuration,
    videoName,
    sessionsQuantity
  ) {
    if (id) {
      navigate('/shows-list', {
        state: { id, blobUrl, videoDuration, videoName, sessionsQuantity },
      });
    }
  }
  

  const newVideoOnClick = () => {
    setShowNewVideo(!showNewVideo);
  };
  const handleSearch = (e) => {
   setSearchValue(e.target.value);
    let compare = e.target.value;
    if (compare !== '') {
      let result = videosReceived.filter((element) => {
        if (element.videoName.toLowerCase().includes(compare.toLowerCase())) {
          return element;
        }
      });
    setVideos(result);
      setReferredArray(result);
    } else {
    setVideos(videosReceived);
      setReferredArray(videosReceived);
    }
  };

  const handleSort = () => {
    let arr = [...videos];

    arr.sort((a, b) => {
      const videoNameA = a.videoName.toLowerCase();
      const videoNameB = b.videoName.toLowerCase();
      if (videoNameA < videoNameB) {
        return sortUp ? -1 : 1;
      }
      if (videoNameA > videoNameB) {
        return sortUp ? 1 : -1;
      }
      return 0;
    });
    setSortUp(!sortUp);
 setVideos(arr);
    setReferredArray(arr);
  };

const chooseVideo=(video)=>{


setVideoChoosed(video)
setShowTechSpec(!showTechSpec)

}


  const onClickCloseChangeVideo = () => {
    setShowRenameVideo(false);
  };
  const onChangeVideoName = (videoId) => {
    setShowRenameVideo(true);
    setVideoSelected(videoId);
  };
  const onClickCloseDeleteVideo = () => {
    setUserWantToDeleteVideo(!userWantToDeleteVideo);
  };
  const setVideoForDelete = (id) => {
    setUserWantToDeleteVideo(!userWantToDeleteVideo);
    setVideoSelected(id);
  };
 

  useEffect(() => {
   let arr = [...referredArray];

    let downDate =
      startDate !== '' ? new Date(startDate) : new Date('01-01-1980');
    let upDate = endDate !== '' ? new Date(endDate) : new Date('01-01-3000');

    let result = arr.filter((val) => {
      let showDate = new Date(val.createdAt);
      if (showDate > downDate && showDate < upDate) {
        return val;
      }
    });
    let isTheSame = JSON.stringify(videos);
    let compare = JSON.stringify(result);
    if (compare !== isTheSame) {
      setVideos(result);
    }
  }, [startDate, endDate, videos]);

 

return (
    <Layout showLeftPart={true}>
      <div className="main-div">
        <div className="search-div-in-main">
          <div className="search-box-and-sort">
            <TextField
              variant="outlined"
              size="small"
              margin="dense"
              placeholder={vocabulary.videoSearch[lang]}
              name="search-box"
              value={searchValue}
              onChange={handleSearch}
              sx={{
                marginTop: '3px',
                marginLeft: '10px',
                background: 'black',
                borderRadius: '5px',
                color: '',
                input: {
                  color: 'white',
                  fontSize: 'smaller',

                  '& ~ .MuiInputAdornment-root': {
                    backgroundColor: 'black',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: '#9E9E9E' }} />
                  </InputAdornment>
                ),
              }}
            />
            <div className="text-in-search">{vocabulary.fromDate[lang]}</div>
            <input
              className="input-login-date"
              type="date"
              id="start-date"
              value={startDate}
              placeholder="DD/MM/AAAA"
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
              style={{ maxWidth: '200px' }}
            />
            <div className="text-in-search">{vocabulary.tillDate[lang]}</div>
            <input
              className="input-login-date"
              type="date"
              id="age"
              placeholder="DD/MM/AAAA"
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
              value={endDate}
              style={{ maxWidth: '200px' }}
            />

            <img
              onClick={handleSort}
              src={sortIcon}
              alt="back-icon"
              className="sort-img"
            />
            <img
              onClick={handleSortDate}
              src={sortDateIcon}
              alt="back-icon"
              className="sort-img"
            />
          </div>
          <div className="sort-box">
            <ToggleButtons handleSetListView={handleSetListView} />
          </div>
        </div>

        {listView ? (
          <div className="sub-main-div">
            <div className="new-video" onClick={newVideoOnClick}>
              <div className="plus-sign"> + </div>
              <div className="text-inside-new-video">
                {vocabulary.newMovieUpload[lang]}
              </div>
            </div>

            {videos?.map((video) => (
              <VideoCardItem
                key={video._id}
                video={video}
                onLoadGet={onLoadGet}
                onClickGetShow={onClickGetShow}
                onChangeVideoName={onChangeVideoName}
                setVideoForDelete={setVideoForDelete}
                lang={lang}
                chooseVideo={chooseVideo}
              />
            ))}

            {showNewVideo && (
              <div className="new-video-upload">
                <NewVideo
                  newVideoOnClick={newVideoOnClick}
                  onLoadGet={onLoadGet}
                  lang={lang}
                />
              </div>
            )}

            {showRenameVideo && (
              <div className="new-video-upload">
                <RenameVideo
                  newVideoOnClick={newVideoOnClick}
                  onLoadGet={onLoadGet}
                  onClickCloseChangeVideo={onClickCloseChangeVideo}
                  videoId={videoSelected}
                  lang={lang}
                />
              </div>
            )}
            {userWantToDeleteVideo && (
              <div className="new-video-upload">
                <DeleteVideo
                  onClickCloseDeleteVideo={onClickCloseDeleteVideo}
                  id={videoSelected}
                  onLoadGet={onLoadGet}
                  lang={lang}
                />
              </div>
            )}

{showTechSpec&& (
              <div className="new-video-upload">
                <TechSpec
                 chooseVideo={chooseVideo}
                 videoChoosed={videoChoosed}
                  lang={lang}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="sub-main-div-list">
            <div className="new-video-list" onClick={newVideoOnClick}>
              <div className="plus-sign"> + </div>
              <div className="text-inside-new-video-list">
                {vocabulary.newMovieUpload[lang]}
              </div>
            </div>

            {videos.map((video) => (
              <VideoListItem
                key={video._id}
                video={video}
                onLoadGet={onLoadGet}
                onClickGetShow={onClickGetShow}
                onClickCloseChangeVideo={onClickCloseChangeVideo}
                onChangeVideoName={onChangeVideoName}
                setVideoForDelete={setVideoForDelete}
                lang={lang}
                chooseVideo={chooseVideo}
              />
            ))}

            {showNewVideo && (
              <div className="new-video-upload">
                <NewVideo
                  newVideoOnClick={newVideoOnClick}
                  onLoadGet={onLoadGet}
                  lang={lang}
                />
              </div>
            )}

            {showRenameVideo && (
              <div className="new-video-upload">
                <RenameVideo
                  newVideoOnClick={newVideoOnClick}
                  onLoadGet={onLoadGet}
                  onClickCloseChangeVideo={onClickCloseChangeVideo}
                  videoId={videoSelected}
                  lang={lang}
                />
              </div>
            )}
            {userWantToDeleteVideo && (
              <div className="new-video-upload">
                <DeleteVideo
                  onClickCloseDeleteVideo={onClickCloseDeleteVideo}
                  id={videoSelected}
                  onLoadGet={onLoadGet}
                  lang={lang}
                />
              </div>
            )}

{showTechSpec&& (
              <div className="new-video-upload">
                <TechSpec
                 chooseVideo={chooseVideo}
                 videoChoosed={videoChoosed}
                  lang={lang}
                />
              </div>
            )}

          </div>
        )}
      </div>
    </Layout>
  );
}
