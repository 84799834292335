import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const ScatterPlot = ({
  statisticData,
  videoInfo,
  selectedVote,
  selectedValue,
}) => {
  const chartRef = useRef(null);
  const scatterChartRef = useRef(null);

  // Function to format milliseconds to hh:mm
  const formatMillisecondsToHHMM = (milliseconds) => {
    const date = new Date(milliseconds);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
  };

  let showedData = statisticData
    .filter((val) => val.userId._id === selectedValue || selectedValue === '0')
    .map((val) => ({
      x: val.time,
      y: val.score === 'true' ? 1 : -1,
      opinion: val.annotation,
      formattedTime: formatMillisecondsToHHMM(val.time),
      id: val._id,
    }));

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    function calculatePointRadius(context) {
      const viewportHeight = window.innerHeight;
      const vhValue = 0.2;
      const pixelValue = (vhValue / 100) * viewportHeight;

      return context.raw.opinion ? pixelValue * 2 : pixelValue;
    }

    const data = {
      datasets: [
        {
          data: showedData,
          pointRadius: (context) => calculatePointRadius(context),
          pointBackgroundColor: (context) =>
            context.parsed.y < 0 ? 'red' : 'green',
        },
      ],
    };

    const config = {
      type: 'scatter',
      data,
      options: {
        plugins: {
          legend: { display: false },
          tooltip: {
            enabled: true,
            callbacks: {
              label: (context) => context.raw.opinion,
            },
            bodyFont: { size: 15 },
            titleFont: { size: 16 },
          },
        },
        scales: {
          x: {
            type: 'linear',
            position: 'center',
            min: 0,
            max: videoInfo.videoDuration,
            beginAtZero: false,
            ticks: {
              callback: (value) =>
                showedData.find((data) => data.x === value)?.formattedTime ||
                '',
            },
          },
          y: {
            display: false,
            type: 'linear',
            position: 'left',
            min: -2,
            max: 2,
          },
        },
      },
    };

    if (scatterChartRef.current) {
      scatterChartRef.current.destroy();
    }

    scatterChartRef.current = new Chart(ctx, config);

    const handleDataPointClick = (event) => {
      const elements = scatterChartRef.current.getElementsAtEventForMode(
        event,
        'nearest',
        { intersect: false, axis: 'x' },
        false
      );

      if (elements.length > 0) {
        const dataPoint = elements[0].index;
        const id = showedData[dataPoint]?.id;
        if (id) {
          selectedVote(id);
        }
      } else {
    
      }
    };

    chartRef.current.addEventListener('click', handleDataPointClick);

    return () => {
      if (scatterChartRef.current) {
        scatterChartRef.current.destroy();
      }
      chartRef.current?.removeEventListener('click', handleDataPointClick);
    };
  }, [selectedValue]);

  return (
    <div className='custom-chart'>
    <canvas ref={chartRef} width="2000px" height="100px"/>
    </div>
  );
};

export default ScatterPlot;
