import { useState } from 'react';
import logo from '../../../images/logo.png';

import LeftMenu from './LeftMenu';
import HeaderMenu from './HeaderMenu';

export default function Layout({
  children,
  showLeftPart,
  videoName,
  menuSection,
  showName,
  navigateBack,
  isItShows,
}) {
  return (
    <div className="row-layout">
      <div className="column-down">
        <div className="left-part-down">
          <div className="logo-corner"></div>

          <LeftMenu isItShows={isItShows} videoName={videoName}navigateBack={navigateBack} />
        </div>

        <div className="right-part-down">{children}</div>
      </div>
    </div>
  );
}
