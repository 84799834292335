import React, {
  useReducer,
  useMemo,
  createContext,
  useState,
  useEffect,
} from 'react';
import { SignInAttempt, SignUpAttempt, sendCode } from './api/authApi';
import { getUserInformation } from './api/workFlowApi';

const GlobalContext = createContext();

const ActionTypes = {
  LOG_IN: 'LOG_IN',
  LOG_OUT: 'LOG_OUT',
  USER_DATA_SET: 'USER_DATA_SET',
  RESTORE_SESSION: 'RESTORE_SESSION',
  SET_USER_INFO: 'SET_USER_INFO',
  SET_CHAT_FLOW: 'SET_CHAT_FLOW',
};

function globalReducer(prevState, action) {
  switch (action.type) {
    case ActionTypes.LOG_IN:
      return {
        ...prevState,
        userToken: action.userToken,
        userInfo: action.userInfo,
      };
    case ActionTypes.LOG_OUT:
      return {
        ...prevState,
        userToken: null,
        userSessionsList: null,
        userInfo: null,
      };

    case ActionTypes.USER_DATA_SET:
      return {
        ...prevState,
        userSessionsList: action.userSessionsList,
      };
    case ActionTypes.RESTORE_SESSION:
      return {
        ...prevState,
        userToken: action.userToken,
      };
    case ActionTypes.SET_USER_INFO:
      return {
        ...prevState,
        userInfo: action.userInfo,
      };
    case ActionTypes.SET_CHAT_FLOW:
      return {
        ...prevState,
        chatFlow: action.chatFlow,
      };

    default:
      return prevState;
  }
}

export function GlobalContextWrapper({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [menuMovieSelected, setMenuMovieSelected] = useState(false);
  const [langSelected, setLangSelected] = useState('en');
  const [isLoading, setIsLoading] = useState(false);



  useEffect(() => {
    GlobalActions.restoreSession();
  }, []);

  const [state, dispatch] = useReducer(globalReducer, {
    userToken: null,
    userSessionsList: null,
    chatFlow: null,
  });

  const GlobalActions = useMemo(
    () => ({
      restoreSession: async () => {
setIsLoading(true)

        const authToken = localStorage.getItem('authToken');

        if (authToken?.length > 10) {
          try {
            dispatch({
              type: ActionTypes.RESTORE_SESSION,
              userToken: authToken,
            });
            setIsLoggedIn(true);
            let userInfo = await getUserInformation();

            dispatch({
              type: ActionTypes.SET_USER_INFO,
              userInfo: userInfo.data.userData,
            });
          } catch (error) {
            console.error('Error restoring token:', error);
          }
        }
        setIsLoading(false)
      },

      setChatFlow: async (data) => {
        try {
          dispatch({
            type: ActionTypes.SET_CHAT_FLOW,
            chatFlow: data,
          });
        } catch (error) {
          console.error('Error setting chat:', error);
        }
      },
      setLang: async (data) => {
        setLangSelected(data);
      },
      logIn: async (data) => {
        debugger;
        try {
          let result = await SignInAttempt(data);
       
          if (result === 'bad credentials') {
            return result;
          }

          const { authToken } = result;

          setIsLoggedIn(true);
          await localStorage.setItem('authToken', authToken);
          dispatch({
            type: ActionTypes.LOG_IN,
            userToken: authToken,
          });
          let userInfo = await getUserInformation();
          dispatch({
            type: ActionTypes.SET_USER_INFO,
            userInfo: userInfo.data.userData,
          });
        } catch (error) {
          console.error('Error logging in:', error);
        }
      },

      sendCode: async (data) => {
        try {
          let result = await sendCode(data);

          if (result === 'Code incorrect.') {
            return result;
          }

          const { authToken } = result;
          localStorage.setItem('authToken', authToken);
          dispatch({
            type: ActionTypes.LOG_IN,
            userToken: authToken,
          });
          setIsLoggedIn(true);

          let userInfo = await getUserInformation();
          dispatch({
            type: ActionTypes.SET_USER_INFO,
            userInfo: userInfo.data.userData,
          });
          return true;
        } catch (error) {
          console.error('Error signing up:', error);
          return false;
        }
      },

      signUp: async (data) => {
        try {
          data.userType = 'admin';
          let result = await SignUpAttempt(data);

          if (
            result === 'User already exists.' ||
            result === 'Code incorrect.'
          ) {
            return result;
          }
          if ('authToken' in result) {
            const { authToken } = result;
            localStorage.setItem('authToken', authToken);
            dispatch({
              type: ActionTypes.LOG_IN,
              userToken: authToken,
            });
            setIsLoggedIn(true);
            let userInfo = await getUserInformation();
            dispatch({
              type: ActionTypes.SET_USER_INFO,
              userInfo: userInfo.data.userData,
            });
          }
          return true;
        } catch (error) {
          console.error('Error signing up:', error);
          return false;
        }
      },

      logOut: async (data) => {
        try {
          localStorage.removeItem('userToken');
          localStorage.removeItem('authToken');
          dispatch({
            type: ActionTypes.LOG_OUT,
            userToken: null,
          });
          setIsLoggedIn(false);
     
        } catch (error) {
          console.error('Error logging in:', error);
        }
      },
      handleMenuClick: async (data) => {
        setMenuMovieSelected(!data);
      },
    }),
    []
  );

  return (
    <GlobalContext.Provider
      value={{
        state,
        isLoggedIn,
        GlobalActions,
        menuMovieSelected,
        langSelected,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalContext;
