import React, { useState } from 'react';
import CsvDownloadButton from 'react-json-to-csv';
import formatTime from '../../../helpers/formatTime';
import arrayToXml from '../../../helpers/ArraytoXML';
import downloadIcon from '../../../images/download-icon.png';
import { vocabulary } from '../../../services/vocabulary';
export default function Downloadable({ show, data, nameButton, lang }) {
  const [csvData, setCSVData] = useState(null);
  const formatedData = [];
  data?.sort((a, b) => {
    return a.time - b.time;
  });

  data?.forEach((val) => {
    let obj = {
      name: val.userId.userName + ' ' + val.userId.surname,
      vote: val.score === 'true' ? 'good' : 'bad',
      annotation: val.annotation,
      time: formatTime(val.time),
    };

    formatedData.push(obj);
  });

  const handleDownload = () => {
    const xmlData = arrayToXml(formatedData);

    const blob = new Blob([xmlData], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = show.showname + '_session_export.xml';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div className='group-buttons-statistic-download '>
      <CsvDownloadButton
        className="download-button"
        data={formatedData}
        filename={show.showName + '_session_export.csv'}
      >
       <img src={downloadIcon} alt="back-icon" className="download-img" />
        &nbsp; {vocabulary.download[lang]} CSV 
      </CsvDownloadButton>
      <button className="download-button" onClick={handleDownload}>
        <img src={downloadIcon} alt="back-icon" className="download-img" />
        &nbsp;{vocabulary.download[lang]} XML 
      </button>
    </div>
  );
}
