import React, { useEffect, useState } from 'react';
import generateRandom from '../../../helpers/randomGenerator';

import closeIcon from '../../../images/close-icon.png';
import { vocabulary } from '../../../services/vocabulary';

export default function UserDetails({
  handleShowUsersDetails,
  usersArray,
  lang,
}) {
  const [showName, setShowName] = useState(undefined);

  const handleClickInsideNewVideo = (e) => {
    e.stopPropagation();
  };

  const handleCloseClick = () => {
    handleShowUsersDetails();
  };


  return (
    <div className="new-video-modal" onClick={handleClickInsideNewVideo}>
      <div className="new-video-form">
        <div className="header-video">
          <div className="text-inside-div-header-new-video">
            {vocabulary.participants[lang]}
          </div>
          <div className="new-video-add-close-icon" onClick={handleCloseClick}>
            <img src={closeIcon} alt="Movies icon" className="menu-icons" />
          </div>
        </div>
        {usersArray.map((val, index) => {
          return (
            <div key={val.userId} className="user-details-div">
              {index + 1}.&nbsp;{val.username}
            </div>
          );
        })}
      </div>
    </div>
  );
}
