export const vocabulary = {
  //left menu
  buttonSubmit: ['submit', 'enviar'],
  myAccount: ['My account', 'Mi cuenta'],
  movies: ['Movies', 'Películas'],
  leave: ['Exit', 'Salir'],
  //login
  startSession: ['Start screening', 'Iniciar proyección'],
  userName: ['User name', 'Nombre del usuario'],
  password: ['Password', 'Contraseña'],
  errorMailPassword: [
    'Wrong email or password',
    'Contraseña o usuario incorrecto',
  ],
  passwordForgotten: ['Forgot your password?', 'Olvidé mi contraseña'],
  enter: ['Enter', 'Entrar'],
  doNotHaveAccount: ['No account? Register', '¿Sin cuenta? Registrarse'],
  termsAndConditions: [
    'By continuing, I agree to the terms.',
    'Al continuar, acepto los términos.',
  ],
  doNotRegistered: ['Not registered?', '¿No estás registrado?'],
  //sign-up
  passwordLongitudError: [
    'Password must be at least 6 characters.',
    'La contraseña debe tener mínimo 6 caracteres.',
  ],
  emailErrorBlank: ['Invalid email format.', 'Formato de correo inválido.'],
  passwordRepeatSymbolsError: [
    'Password must not have consecutive repeated characters.',
    'La contraseña no debe repetir caracteres consecutivos.',
  ],
  register: ['Register', 'Registrarse'],
  namesAndSurnames: ['Full name', 'Nombre completo'],
  email: ['Email', 'Correo'],
  confirmPassword: ['Confirm password', 'Confirmar contraseña'],
  passwordDoesNotMatch: [
    'Passwords Do Not Match.',
    'Contraseñas No Coinciden.',
  ],
  termsAndConditionsHeader: ['Terms & Conditions', 'Términos y Condiciones'],
  agree: ['Agree', 'De acuerdo'],
  register: ['Sign Up', 'Registrarse'],
  enterCode: ['Enter code', 'Ingresar Código'],
  //confirmation code
  confirmationCode: ['Confirmation code', 'Código de Confirmación'],
  sendCode: ['Send Code', 'Enviar Código'],
  incorrectCode: ['Incorrect Code', 'Código Incorrecto '],
  emailSent: [
    'Check your email to activate account',
    'Revise su correo para activar cuenta',
  ],
  emailError: ['Email already exists', 'Correo ya existente'],
  //small screen
  needMoreResolution: [
    'Higher screen resolution required',
    'Se requiere máyor resolución de pantalla.',
  ],
  resolutionWarning: [
    'Use a computer for best experience.',
    'Use computador / ordenador para una mejor experiencia',
  ],
  //videos
  videoSearch: ['Search Movies', 'Buscar Películas'],
  newMovieUpload: ['Upload New Movie', 'Subir Nueva Película'],
  sessions: ['Screenings', 'Proyecciones'],
  seeSessions: ['View Screenings', 'Ver Proyecciones'],
  detailsChange: ['Rename Movie', 'Renombrar Película'],
  eraseMovie: ['Delete Movie', 'Borrar Película'],
  details: ['Technical Specs', ' Especificaciones Técnicas'],
  fromDate: ['From: ', 'De: '],
  tillDate: ['To: ', 'Hasta: '],
  newMovie: ['New movie name','Nombre de Película'],
  //delete
  deleteVideo: [
    'Are you sure you want to delete this movie? ',
    '¿Realmente quiere borrar esta película?',
  ],
  cancel: ['Cancel', 'Cancelar'],
  erase: ['Delete', 'Borrar'],
  deleteVideoHeader: ['Delete Video', 'Borrar Video'],
  //rename
  renameMovieHeader: ['Rename Movie', 'Renombrar Película'],
  movieName: ['Movie name', 'Nombre película'],
  addMovie: ['Add movie', 'Agregar película'],
  newMovieName: ['Movie name', ' Nombre de la Película'],
  uploadMovie: ['Upload Movie:', 'Subir Película:'],
  dropFileHere: ['Drop File Here', 'Soltar Archivo Aquí'],
  dragNDropFileHere: ['Drag and Drop File Here', 'Arrastrar y Soltar Aquí'],
  selectFile: ['Select File', 'Seleccionar Archivo'],
  selectedFile: ['Selected File', 'Archivo Seleccionado'],
  //sessions
  sessionSearch: ['Search Screenings', 'Buscar Proyecciones'],
  addSession: ['Add Screening', 'Crear Proyección'],
  session: ['Screening ', 'Proyección'],
  sessionProgrammed: ['Screening Scheduled', 'Proyección Programada'],
  sessionEnded: ['Screening Ended', 'Proyección Finalizada'],
  //detail
detailsVideo:['Video file details',"Detalles de la película"],

  //context
  showQR: ['Show QR', 'Mostrar QR'],
  changeSession: ['Change Screening', 'Cambiar Proyección'],
  deleteSession: ['Delete Screening', ' Eliminar Proyección'],
  startSession: ['Start Screening', 'Iniciar Proyección'],
  showStatistics: ['Show Data', 'Ver Datos'],
  //new session
  newSession: ['New Screening', 'Crear Proyección'],
  sessionName: ['Screening Name', 'Nombre de la Proyección'],
  date: ['Date', 'Fecha'],
  time: ['Time', 'Hora'],
  dateError: ['Date must be valid', 'La fecha debe ser válida'],
  timeError: ['Time must be valid', 'La hora debe ser válida'],
  //QR
  download: ['Download', 'Descargar'],
  qRSend: ['Send QR/URL', 'Enviar QR/URL'],
  linkToJoin: ['Link to Join', 'Enlace para Participar'],
  thanks: ['Thanks a lot', 'Muchas gracias'],
  //delete
  areYouSureToDeleteSession: [
    'Are You Sure? All Data Will Be Lost.',
    '¿Estás Seguro? Se Perderán Todos los Datos.',
  ],
  //player
  seeParticipants: ['See Participants', 'Ver Participantes'],
  closeSession: ['Close Screening', 'Cerrar Proyección'],
  participants: ['Participants', 'Participantes'],
  //statistic
  nobodyCame: [
    'Nobody Participated in This Screening',
    'Nadie Participó en Esta Proyección',
  ],
  comments: ['Comments', 'Comentarios'],
  all: ['All', 'Todos'],
  showAllComments: ['Show All Comments', 'Ver Todos los Comentarios'],
  ended: ['Ended', 'Terminada'],
  //user menu
  phoneNumber: ['Phone Number ', 'Número de Teléfono'],
  direction: ['Address', 'Dirección'],
  occupation: ['Occupation', 'Ocupación'],
  civilStatus: ['Marital Status', 'Estado civil'],
  city: ['City', 'Ciudad'],
  gender: ['Gender', 'Género'],
  educationLevel: ['Education Level', 'Nivel de Educación'],
  interest: ['Interests', 'Intereses'],
  updateAccount: ['Update Account', 'Actualizar Cuenta'],
  surname: ['Family Name', 'Apellido'],
  //details
  videoDetail: ['Technical Specs', 'Especificaciones Técnicas'],
};
