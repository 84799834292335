import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from '../../GlobalContext';
import { useNavigate } from 'react-router-dom';
import logoGoogle from '../../images/google-logo.png';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useSearchParams } from 'react-router-dom';
import logoIcon from './../../images/logo.png';
import backIcon from '../../images/back-arrow.png';
import { vocabulary } from '../../services/vocabulary';

export default function CodeConfirm() {
  const { GlobalActions, langSelected } = useContext(GlobalContext);
  const [codeInForm, setCodeInForm] = useState('');
  const [showLoginError, setShowLoginError] = useState(false);
  const [lang, setLang] = useState(0);
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const navigate = useNavigate();
  const navigateBack = () => {
    navigate('/');
  };
  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);

  useEffect(() => {
    GlobalActions.sendCode(code);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let res = await GlobalActions.sendCode({
      code: codeInForm,
    });
    if (res === 'Code incorrect.') {
      setShowLoginError(true);
    }
  };

  return (
    <div className="login-screen">
      <div className="login-left-part">
        <div className="logo-group-login">
          <img src={logoIcon} alt="Movies icon" className="logo-login" />
        </div>
        <div className="tapper"></div>
      </div>

      <div className="login-container-email-sent">
        <img
          src={backIcon}
          alt="back-icon"
          className="logout-icon"
          onClick={navigateBack}
        />
        <div className="login-box-mail-sent">
          <div className="form-group">
            <div className="input-div">
              <div className="login-header"> {vocabulary.enterCode[lang]}</div>
            </div>
            <div className="input-div">
              <TextField
                variant="outlined"
                style={{ color: 'white' }}
                fullWidth
                margin="normal"
                placeholder={vocabulary.confirmationCode[lang]}
                name="code"
                value={codeInForm}
                onChange={(e) => {
                  setCodeInForm(e.target.value);
                }}
                type={'text'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="center">
                      <IconButton edge="center"></IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {showLoginError && (
                <div className="error-message">
                  {vocabulary.incorrectCode[lang]}
                </div>
              )}
            </div>
            <div className="input-div">
              <Button
                variant="outlined"
                color="primary"
                style={{
                  width: '100%',
                  height: '50px',
                  marginTop: '20px',
                  backgroundColor: '#0D1C28',
                  color: 'white',
                }}
                fullWidth
                margin="normal"
                onClick={handleSubmit}
              >
                {vocabulary.sendCode[lang]}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
