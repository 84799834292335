import enterIcon from '../../../images/enter-icon.png';
import penIcon from '../../../images/pen-icon.png';
import eraserIcon from '../../../images/eraser-icon.png';
import movieIcon from '../../../images/movie-icon.png';
import { vocabulary } from '../../../services/vocabulary';
import { useNavigate } from 'react-router-dom';

export default function ContextMenuVideoList({
  onClickShow,
  onClickRename,
  onClickDelete,
  lang,
  video,
  chooseVideo,
}) {
  const navigate = useNavigate();
  const handleDetails = () => {
  
    chooseVideo(video)
  };

  return (
    <div className="context-menu-div-list">
      <div className="context-menu-center-text" onClick={onClickShow}>
        <div className="icon-submenu">
          <img src={enterIcon} alt="Enter icon" className="menu-icons" />
        </div>
        {vocabulary.seeSessions[lang]}
      </div>
      <div className="line-div" />
      <div className="context-menu-center-text" onClick={onClickRename}>
        <div className="icon-submenu">
          <img src={penIcon} alt="Pen icon" className="menu-icons" />
        </div>
        {vocabulary.detailsChange[lang]}
      </div>
      <div className="line-div" />
      <div className="context-menu-center-text" onClick={onClickDelete}>
        <div className="icon-submenu">
          <img src={eraserIcon} alt="Eraser icon" className="menu-icons" />
        </div>
        {vocabulary.eraseMovie[lang]}
      </div>
      <div className="line-div" />
      <div className="context-menu-center-text" onClick={handleDetails}>
        <div className="icon-submenu">
          <img src={movieIcon} alt="Movie icon" className="menu-icons" />
        </div>
        {vocabulary.details[lang]}
      </div>
    </div>
  );
}
