import React, { useContext, useEffect, useState } from 'react';
import movieIcon from '../../../images/movie-white-icon.png';
import movieCloseIcon from '../../../images/movie-close-white-icon.png';
import accountIcon from '../../../images/person-white-icon.png';
import logoutIcon from '../../../images/logout-icon.png';
import connectorIcon from '../../../images/connector-icon.png';
import { useNavigate } from 'react-router-dom';
import GlobalContext from '../../../GlobalContext';
import { vocabulary } from '../../../services/vocabulary';
import worldIcon from '../../../images/world-icon.png';
export default function LeftMenu({ isItShows, videoName,navigateBack }) {
  const { menuMovieSelected, GlobalActions, langSelected } =
    useContext(GlobalContext);
  const [lang, setLang] = useState(navigator.language !== 'es' ? 0 : 1);

  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  useEffect(() => {
    GlobalActions.restoreSession();
  }, []);

  const handleExit = () => {
    GlobalActions.logOut();
    navigate('/');
  };

  const handleMenuClick = () => {
    GlobalActions.handleMenuClick(true);
    handleNavigation('/main');
  };

  const handleLangChange = () => {
    if (lang === 0) {
      GlobalActions.setLang('es');
    } else {
      GlobalActions.setLang('en');
    }
  };
 
    const handleInnerClick = (e) => {
      e.stopPropagation();
      navigateBack()
     
    };
  return (
    <div className="left-menu">
      <div
        className={
          menuMovieSelected || isItShows
            ? 'left-menu-part'
            : 'left-menu-part-black'
        }
        onClick={handleMenuClick}
      >
        <div className="menu-punt">
          <img src={movieIcon} alt="Movies icon" className="menu-icons" />
          &nbsp; {vocabulary.movies[lang]}
        </div>

        {isItShows && (
          <div className="left-menu-part-black-1" onClick={handleInnerClick}>
            <div className="connector" />{' '}
            <img
              src={movieCloseIcon}
              alt="Movies icon"
              className="menu-icons"
            />
            {videoName.substring(0, 11)}
          </div>
        )}
      </div>

      <div
        className={
          !menuMovieSelected ? 'left-menu-part' : 'left-menu-part-black'
        }
        onClick={() => {
          GlobalActions.handleMenuClick(false);
          handleNavigation('/user-menu');
        }}
      >
        <div className="menu-punt">
          <img src={accountIcon} alt="Movies icon" className="menu-icons" />
          &nbsp; {vocabulary.myAccount[lang]}
        </div>
      </div>
      <div className={'left-menu-part'} onClick={handleLangChange}>
        <div className="menu-punt">
          <img src={worldIcon} alt="Movies icon" className="menu-icons" />
          &nbsp; {lang === 0 ? 'Español' : 'English'}
        </div>
      </div>

      <div className={'left-menu-part'} onClick={handleExit}>
        <div className="menu-punt">
          <img src={logoutIcon} alt="Movies icon" className="menu-icons" />
          &nbsp; {vocabulary.leave[lang]}
        </div>
      </div>
    </div>
  );
}
