import { Button } from "@mui/material";
import { vocabulary } from "../../../services/vocabulary";
import closeIcon from '../../../images/close-icon.png';
export default function TechSpec({lang,chooseVideo,videoChoosed}){

const video=videoChoosed;

   const handleClose =()=>{

    chooseVideo("")

   }
   let videoInfo = JSON.parse(video.aditional_video_info);
   return ( 
    <div className="detail-video-form">
      <div className="header-delete-video">
        <div className="text-inside-div-header-delete-video">
          {vocabulary.detailsVideo[lang]}
        </div>
        <div
          className="new-video-add-close-icon"
          onClick={handleClose}
        >
          <img src={closeIcon} alt="Movies icon" className="menu-icons" />
        </div>
      </div>
      <div className="details-element-text">

    
          <div className="half-details-main-div">
            {Object.keys(videoInfo).map((key) => (
              <div className="details-row-element">
                <h4> {key}</h4>
                <div className="info-inside-row">
                  {' '}
                  {JSON.stringify(videoInfo[key])}
                </div>
              </div>
            ))}
          </div>














      </div>

      <div className="delete-line" />
      <div className="button-block-detail">
   

      
          <Button
            variant="outlined"
            color="primary"
            style={{
              width: '100%',
              height: '50px',
             backgroundColor: 'black',
              color: 'white',
            }}
            fullWidth
            margin="normal"
            onClick={handleClose}
          >
            {vocabulary.cancel[lang]}
          </Button>
        </div>
    
    </div>

);
}