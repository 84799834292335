import { useLocation } from 'react-router-dom';
import Layout from '../Components/Layout/Layout';
import { vocabulary } from '../../services/vocabulary';
import { useEffect, useState } from 'react';

export default function VideoDetailsScreen() {
  const location = useLocation();
  const { video, lang } = location.state;
  const [blobUrl, setBlobURL] = useState(null);

  useEffect(() => {
    if (video.fileImage) {
      let bufferObject = JSON.parse(video.fileImage);
      const uint8Array = new Uint8Array(bufferObject.data);
      let blob = new Blob([uint8Array], { type: bufferObject.type });
      setBlobURL(URL.createObjectURL(blob));
    }
  }, []);

  let videoInfo = JSON.parse(video.aditional_video_info);


  return (
    <Layout showLeftPart={true}>
      <div className="main-div">
        <div className="details-main-div">
          <h2>{video.videoName}</h2>
          <h2>{vocabulary.videoDetail[lang]}</h2>
    
          <div className="half-details-main-div">
            {Object.keys(videoInfo).map((key) => (
              <div className="details-row-element">
                <h4> {key}</h4>
                <div className="info-inside-row">
                  {' '}
                  {JSON.stringify(videoInfo[key])}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
