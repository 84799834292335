import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from '../../GlobalContext';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import { passwordRecovery } from '../../api/authApi';
import backIcon from '../../images/back-arrow.png';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import logoIcon from './../../images/logo.png';
export default function PasswordRecoveryScreen() {
  const { isLoggedIn } = useContext(GlobalContext);
  const [email, setEmail] = useState('');
  const { state, GlobalActions, langSelected } = useContext(GlobalContext);
  const [showloginError, setShowLoginError] = useState(false);
  const [password, setUserPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let res = passwordRecovery({ email: email });

    if (res === 'bad credentials') {
      setShowLoginError(true);
    } else {
      navigateBack();
    }
  };

  const navigateBack = () => {
    navigate('/');
  };
  return (
    <div className="login-screen">
      <div className="login-left-part">
        <div className="logo-group-login">
          {' '}
          <img src={logoIcon} alt="Movies icon" className="logo-login" />
        </div>
        <div className="tapper"></div>
      </div>

      <div className="login-container-recovery">
        <img
          src={backIcon}
          alt="back-icon"
          className="logout-icon"
          onClick={navigateBack}
        />
        <div className="login-box">
          <form className="form-login" onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="input-div">
                <div className="login-header">Recuperar contraseña</div>
              </div>
              <label htmlFor="e-mail"></label>
              <div className="input-div">
                <TextField
                  variant="outlined"
                  autoComplete="email"
                  fullWidth
                  margin="normal"
                  placeholder="Email"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setShowLoginError(false);

                    setEmail(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              {showloginError && (
                <div className="error-message">{'Bad e-mail '}</div>
              )}

              <div className="input-div">
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    width: '100%',
                    height: '50px',
                    marginTop: '20px',
                    backgroundColor: '#0D1C28',
                    color: 'white',
                  }}
                  fullWidth
                  margin="normal"
                  onClick={handleSubmit}
                >
                  Restablecer
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
