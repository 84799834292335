import displayIcon from '../../images/display.png';
import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from '../../GlobalContext';

import { vocabulary } from '../../services/vocabulary';

export default function SmallScreen() {
  const { langSelected, state } = useContext(GlobalContext);

  const [lang, setLang] = useState(0);

  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);

  return (
    <div className="small-screen-div">
      <div className="small-screen-text">
        <div className="circle-small-screen">
          <img src={displayIcon} alt="How it works?" className="display-sign" />
        </div>

        <div className="header-text-small-screen">
          {vocabulary.needMoreResolution[lang]}
        </div>
        <div className="text-small-screen">
          {vocabulary.resolutionWarning[lang]}
        </div>
      </div>
    </div>
  );
}
