export default function arrayToXml(dataArray) {
  let xml = '<data>';

  dataArray.forEach((item) => {
    xml += '<item>';
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        xml += `<${key}>${item[key]}</${key}>`;
      }
    }
    xml += '</item>';
  });

  xml += '</data>';
  return xml;
}
