import React, { useEffect, useState } from 'react';
import generateRandom from '../../../helpers/randomGenerator';
import { postNewVideo } from '../../../api/workFlowApi';
import closeIcon from '../../../images/close-icon.png';
import FileUpload from './FileUpload';
import load from '../../../images/load.gif';
import TextField from '@mui/material/TextField';
import { vocabulary } from '../../../services/vocabulary';
import LinearWithValueLabel from './ProgressBar';

export default function NewVideo({ onLoadGet, newVideoOnClick, lang }) {
  const [videoName, setVideoName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorShow, setErrorShow] = useState(false);
  const [isLoadFile, setIsLoadFile] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const handleNameChange = (e) => {
    setVideoName(e.target.value);
  };

  const handleFileUpload = async (selectedFile) => {
    let randomID = generateRandom();
    setIsLoadFile(true);
    let data = { videoId: randomID, videoName: videoName, file: selectedFile };

    try {
      let newVideo = await postNewVideo(data, {
        onUploadProgress: setUploadPercentage,
      }).then((response) => {
    
      });

      if (newVideo === 'error') {
        setErrorShow(true);
      } else {
        setVideoName('');
        setSelectedFile(null);
        setIsLoadFile(false);
        onLoadGet();
        newVideoOnClick();
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleClickInsideNewVideo = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="new-video-modal" onClick={handleClickInsideNewVideo}>
      <div className="new-video-form">
        <div className="header-video">
          <div className="text-inside-div-header-new-video">
            {vocabulary.addMovie[lang]}
          </div>
          <div className="new-video-add-close-icon" onClick={newVideoOnClick}>
            <img src={closeIcon} alt="Movies icon" className="menu-icons" />
          </div>
        </div>
        <div className="download-element-input">
          <label className="label-input-text" htmlFor="name">
            {vocabulary.movieName[lang]}
          </label>
          <div className="input-element-name">
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              placeholder={vocabulary.newMovie[lang]}
              name="new-video"
              onChange={handleNameChange}
              value={videoName}
              disabled={isLoadFile}
              sx={{
                background: 'white',
                borderRadius: '10px',
                input: {
                  color: 'black',

                  '& ~ .MuiInputAdornment-root': {
                    backgroundColor: '#fff',
                  },
                },
              }}
            />
          </div>
          {videoName && (
            <>
              {' '}
              <div className="progress-bar">
                <LinearWithValueLabel progress={uploadPercentage} />
              </div>
              <FileUpload handleFileUpload={handleFileUpload} lang={lang} />
            </>
          )}
        </div>
        <div>
          <div className="input-element">
            <label htmlFor="file"></label>
          </div>
          <div className="input-element"></div>
        </div>
      </div>
      {errorShow && (
        <div className="error-message">{'Formato de archivo incorrecto'}</div>
      )}
    </div>
  );
}
