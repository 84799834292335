import React, { useEffect, useState } from 'react';
import generateRandom from '../../../helpers/randomGenerator';
import Button from '@mui/material/Button';
import closeIcon from '../../../images/close-icon.png';
import TextField from '@mui/material/TextField';
import { postNewShow } from '../../../api/workFlowApi';
import { vocabulary } from '../../../services/vocabulary';

export default function NewShow({
  onLoadGet,
  videoId,
  videoDuration,
  blobUrl,
  newShowOnClick,
  sessionsQuantity,
  numberShowIncrease,
  lang,
}) {
  const [showName, setShowName] = useState("");
  const [timeStart, setTimeStart] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [minDate, setMinDate] = useState('');
  const [showDateError, setShowDateError] = useState(false);
  const [showTimeError, setShowTimeError] = useState(false);
  const handleNameChange = (e) => {
    setShowName(e.target.value);
  };

  const handleClickInsideNewVideo = (e) => {
    e.stopPropagation();
  };

  const handleDateStart = (e) => {
    setShowDateError(false);
    setDateStart(e.target.value);
  };
  const handleTimeStart = (e) => {
    setShowTimeError(false);
    setTimeStart(e.target.value);
  };

  const handleSubmit = async () => {
    let randomID = generateRandom();

    if (dateStart && timeStart) {
      let showStartTime = dateStart + ' ' + timeStart;
      try {
        await postNewShow({
          showId: randomID,
          showName: showName,
          videoDuration: videoDuration,
          videoId: videoId,
          showStartTime: showStartTime,
          sessionsQuantity: sessionsQuantity,
        });
        numberShowIncrease();
        await onLoadGet();
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      if (!dateStart) {
        setShowDateError(true);
      }
      if (!timeStart) {
        setShowTimeError(true);
      }
    }
  };

  const handleCloseClick = () => {
    newShowOnClick();
  };

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const currentDate = `${year}-${month}-${day}`;

    setMinDate(currentDate);
  }, []);

  return (
    <div className="new-video-modal" onClick={handleClickInsideNewVideo}>
      <div className="new-video-form">
        <div className="header-video">
          <div className="text-inside-div-header-new-video">
            {vocabulary.newSession[lang]}
          </div>
          <div className="new-video-add-close-icon" onClick={handleCloseClick}>
            <img src={closeIcon} alt="Movies icon" className="menu-icons" />
          </div>
        </div>
        <div className="create-session-element-inputs">
          <label className="label-input-text" htmlFor="name">
            {vocabulary.newSession[lang]}
          </label>

          <div className="input-element-name">
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              placeholder=""
              name="showName"
              onChange={handleNameChange}
              value={showName}
              sx={{
                background: 'white',
                borderRadius: '10px',
                input: {
                  color: 'black',

                  '& ~ .MuiInputAdornment-root': {
                    backgroundColor: '#fff',
                  },
                },
              }}
            />
          </div>
          <div className="part-list-item">
            <div className="time-date-set">
              <div className="time-date-set-text">
                {vocabulary.date[lang]}
                <TextField
                  variant="outlined"
                  fullWidth
                  type="date"
                  margin="normal"
                  name="startDate"
                  onChange={handleDateStart}
                  value={dateStart}
                  min={minDate}
                  inputProps={{ min: minDate }}
                />
               
                {showDateError && (
                  <div className="error-message-modal">
                    {vocabulary.dateError[lang]}
                  </div>
                )}{' '}
              </div>
              <div className="time-date-set-text">
                {vocabulary.time[lang]}
                <TextField
                  variant="outlined"
                  fullWidth
                  type="time"
                  margin="normal"
                  name="startDate"
                  onChange={handleTimeStart}
                  value={timeStart}
                  sx={{
                    background: 'white',
                    borderRadius: '10px',
                    width: '100%',
                  }}
                />
                {showTimeError && (
                  <div className="error-message-modal">
                    {vocabulary.timeError[lang]}
                  </div>
                )}
              </div>
            </div>

            <div className="new-show-line" />
            <div className="button-block">
              <div className="time-date-set-text">
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    width: '100%',
                    height: '50px',
                    marginTop: '20px',
                    borderColor: '#9e9e9e',
                    color: 'black',
                  }}
                  fullWidth
                  margin="normal"
                  onClick={handleCloseClick}
                >
                  {vocabulary.cancel[lang]}
                </Button>
              </div>
              <div className="time-date-set-text">
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    width: '100%',
                    height: '50px',
                    marginTop: '20px',
                    backgroundColor: '#0D1C28',
                    color: 'white',
                  }}
                  fullWidth
                  margin="normal"
                  onClick={handleSubmit}
                >
                  {vocabulary.newSession[lang]}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}
