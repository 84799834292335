import axios from 'axios';
const baseUrl = process.env.REACT_APP_SERVER_API_URL;

export async function SignInAttempt(data) {
  const apiUrl = baseUrl + `/public/login-admin`;

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  };
  let response;

  try {
    response = await axios.post(apiUrl, data, config);
  } catch (error) {
    console.error('[!] Error:', error);
    if (error.response.status === 401) {
      return 'bad credentials';
    } else {
      throw new Error('An error occurred during API call');
    }
  }

  return response.data;
}

export async function passwordRecovery(data) {
  const apiUrl = baseUrl + `/public/password-recovery`;

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  };
  let response;

  try {
    response = await axios.put(apiUrl, data, config);
  } catch (error) {
    console.error('[!] Error:', error);
    if (error.response.status === 401) {
      return 'bad credentials';
    } else {
      throw new Error('An error occurred during API call');
    }
  }

  return response.data;
}

export async function SignUpAttempt(data) {
  const apiUrl = baseUrl + `/public/signup-admin`;

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  };
  let response;
  try {
    response = await axios.post(apiUrl, data, config);
  } catch (error) {
    console.log(' Error:', error);
    return error.response.data.message;
  }

  return response.data;
}

export async function sendCode(data) {
  try {
    const response = await axios.get(
      baseUrl + '/public/send-code?code=' + data
    );
    const result = response.data;

    return result;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}
