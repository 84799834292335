import { useEffect, useState } from 'react';

import React from 'react';
import ScatterPlot from './ScatterPlot';

export default function Statistics({
  statisticData,
  videoInfo,
  selectedVote,
  selectedValue,
}) {
  const [userNameArray, setUserNameArray] = useState([]);

  useEffect(() => {
    if (statisticData !== null) {
      const uniqueUserArray = statisticData.reduce(
        (acc, val) => {
          if (!acc.idMap.has(val.userId._id)) {
            acc.idMap.set(val.userId._id, true);
            acc.resultArray.push({
              id: val.userId._id,
              name: val.userId.userName,
            });
          }
          return acc;
        },
        { idMap: new Map(), resultArray: [] }
      ).resultArray;

      setUserNameArray(uniqueUserArray);
    }
  }, []);

  if (statisticData) {
    return (
    
        <ScatterPlot
          statisticData={statisticData}
          videoInfo={videoInfo}
          selectedVote={selectedVote}
          selectedValue={selectedValue}
        />

    );
  }
}
