import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../Components/Layout/Layout';
import {
  changeVote,
  downloadShow,
  getStatisticsShow,
} from '../../api/workFlowApi';
import { useEffect, useState, useRef, useContext } from 'react';
import GlobalContext from '../../GlobalContext';
import UserDetails from '../Components/PlayerScreen/UsersDetails';
import Statistics from '../Components/ShowEndedScreen/Statistics';
import formatTime from '../../helpers/formatTime';
import good from '../../images/good.png';
import bad from '../../images/bad.png';
import messageIcon from '../../images/message-icon.png';
import chatIcon from '../../images/chat-icon.png';
import showEnded from '../../images/show-ended.png';
import Downloadable from '../Components/ShowEndedScreen/DownLoadable';
import ChatWindow from '../Components/ShowEndedScreen/ChatWindow';
import { vocabulary } from '../../services/vocabulary';

export default function ShowEndedScreen() {
  const location = useLocation();
  var fileUrl = process.env.REACT_APP_SERVER_API_URL + '/public/player?id=';
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [usersArray, setUsersArray] = useState([]);
  const { state, GlobalActions, langSelected } = useContext(GlobalContext);
  const [videoUrl, setVideoUrl] = useState('');
  const [showUsersDetails, setShowUsersDetails] = useState(false);
  const { blobUrl, show, sessionsQuantity } = location.state;
  const [data, setData] = useState(null);
  const [videoInfo, setVideoInfo] = useState(null);
  const [showComment, setShowComment] = useState(null);
  const id = show.showId;
  const videoName = show.videoId.videoName;
  const showName = show.showName;
  const showStartTime = show.showStartTime;
  const [selectedValue, setSelectedValue] = useState('0');
  const [isEditing, setEditing] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [showChat, setShowChat] = useState(false);

  const [lang, setLang] = useState(0);

  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);

  useEffect(() => {
    const videoStream = async () => {
      try {
        const response = await downloadShow(id);

        if (response.status === 200 || response.status === 206) {
          setVideoUrl(fileUrl + response.data.uuid);
        } else {
          throw new Error(
            `Failed to fetch video. Status code: ${response.status}`
          );
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    videoStream();
  }, [id]);

  const navigateBack = () => {
    navigate('/shows-list', {
      state: {
        blobUrl: blobUrl,
        id: show.videoId._id,
        videoDuration: show.videoId.videoDuracion,
        videoName,
        sessionsQuantity,
      },
    });
  };

  const getStat = async () => {
    let stat = await getStatisticsShow(id);
    setData(stat);

    const videoInfo = stat.splice(0, 1);
    setVideoInfo(videoInfo);
    const uniqueIds = new Map();

    for (const obj of stat) {
      uniqueIds.set(
        obj.userId._id,
        obj.userId.userName + ' ' + obj.userId.surname
      );
    }

    const arr = Array.from(uniqueIds, ([userId, username]) => ({
      userId,
      username,
    }));

    setUsersArray(arr);
  };

  useEffect(() => {
    getStat();
  }, []);

  const handleShowUsersDetails = () => {
    setShowUsersDetails(!showUsersDetails);
  };

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);

  };

  useEffect(() => {
    if (showComment) {
      const videoElement = videoRef.current;

      const foundTime = data.find((item) => item._id === showComment);

      videoElement.currentTime = parseInt(foundTime.time) / 1000;
    }
  }, [showComment]);

  const selectedVote = (id) => {

    setShowComment(id);
  };

  const handleDoubleClick = (e) => {
    e.stopPropagation();
    setEditing(true);
  };

  const handleBlur = () => {
    setEditing(false);
  };

  const handleChange = (e) => {
    setCommentText(e.target.value);
  };
  const handleKeyDown = (e, index) => {
    if (e.key === 'Enter') {
      data[index].annotation = commentText;
      changeVote({
        annotation: commentText,
        _id: data[index]._id,
        showId: data[index].showId,
      });
      setEditing(false);
    }
  };
  const handleShowChat = () => {
    setShowChat(!showChat);
  };

  return (
    <Layout
      showLeftPart={true}
      videoName={videoName}
      menuSection={'Películas'}
      showName={showName}
      navigateBack={navigateBack}
      isItShows={true}
    >
      <div className="search-div-in-player">
        <div className="text-up-player">
          <h4>{showStartTime}</h4>
          {/*} <h4 onClick={handleShowUsersDetails}>
            Ver paricipantes:({usersArray.length ? usersArray.length : 0}){' '}
  </h4>*/}
        </div>
      </div>
      <div className="video-player-main-div">
        <div className="player-and-stats-column">
          <div className="player-statistic">
            {videoUrl ? (
              <>
                <video
                  ref={videoRef}
                  controls
                  style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  autoPlay={false}
                >
                  <source src={videoUrl} />
                  Your browser does not support the video tag.
                </video>
              </>
            ) : null}
          </div>

          {data !== null ? (
            data.length > 0 ? (
              <Statistics
                statisticData={data}
                videoInfo={videoInfo[0]}
                selectedVote={selectedVote}
                selectedValue={selectedValue}
                lang={lang}
              />
            ) : (
              <div className="nobody-was">{vocabulary.nobodyCame[lang]}</div>
            )
          ) : null}
        </div>

        <div className="user-reactions-main-div">
          <div className="comments-statistic">
            <h5 className="text-comment-statistic">
              {vocabulary.comments[lang]}
            </h5>
            <div className="group-buttons-statistic-download">
              <Downloadable show={show} data={data} lang={lang} />
            </div>
          </div>

          <div className="line-comments" />
          <div className="search-box-statistic">
            <select
              className="users-select"
              value={selectedValue}
              onChange={handleSelectChange}
            >
              <option value="0">{vocabulary.all[lang]}</option>
              {data !== null
                ? usersArray.map((val, index) => {
                    return (
                      <option key={JSON.stringify(val)} value={val.userId}>
                        {val.username}
                      </option>
                    );
                  })
                : null}
            </select>

            <p
              onClick={() => {
                setSelectedValue('0');
              }}
              className="ver-comments"
            >
              {vocabulary.showAllComments[lang]}
            </p>
          </div>
          <div className="users-reactions-div">
            {data !== null
              ? data.map((val, index) => {
                  let isOdd = index % 2;

                  if (
                    val.userId._id === selectedValue ||
                    selectedValue === '0'
                  ) {
                    return (
                      <div key={val._id}>
                        <div
                          className={
                            showComment !== val._id
                              ? isOdd === 0
                                ? 'search-box-statistic'
                                : 'search-box-statistic-odd'
                              : 'search-box-statistic-selected'
                          }
                        >
                          <div className="timecode">
                            <div className="text-comment-statistic">
                              {formatTime(val.time).slice(0, -4)}
                            </div>
                          </div>
                          <div className="user-name-statistic">
                            {val.userId.userName + ' ' + val.userId.surname}
                          </div>
                          <div
                            className="reaction-statistic"
                            onClick={() => {
                              if (showComment === val._id) {
                                setShowComment(null);
                              } else {
                                setShowComment(val._id);
                                setCommentText(val.annotation);
                              }
                            }}
                          >
                            <img
                              src={val.score === 'true' ? good : bad}
                              alt="back-icon"
                              className="reaction-img"
                            />
                          </div>
                          <div
                            onClick={() => {
                              if (showComment === val._id) {
                                setShowComment(null);
                              } else {
                                setShowComment(val._id);
                                setCommentText(val.annotation);
                              }
                            }}
                          >
                            {val.annotation ? (
                              <img
                                src={messageIcon}
                                alt="message-icon"
                                className="reaction-img"
                              />
                            ) : (
                              <div className="reaction-img" />
                            )}
                          </div>
                        </div>
                        {showComment === val._id && val.annotation ? (
                          <div
                            className="search-box-statistic-selected-comment"
                            onDoubleClick={handleDoubleClick}
                          >
                            {isEditing ? (
                              <input
                                type="text"
                                value={commentText}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                autoFocus
                                className="input-on-comments"
                              />
                            ) : (
                              <div className="search-box-statistic-selected-comment-text">
                                {val.annotation}
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    );
                  }
                })
              : null}
          </div>
          <img
            src={chatIcon}
            alt="message-icon"
            className="chat-icon"
            onClick={handleShowChat}
          />

          {showChat && (
            <div className="chat-window">
              <ChatWindow handleShowChat={handleShowChat} data={data} />
            </div>
          )}
        </div>
      </div>

      {showUsersDetails && (
        <div className="new-video-upload">
          <UserDetails
            usersArray={usersArray}
            handleShowUsersDetails={handleShowUsersDetails}
          />
        </div>
      )}
    </Layout>
  );
}
