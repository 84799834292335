import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from '../../GlobalContext';
import { useNavigate } from 'react-router-dom';

import logoIcon from './../../images/logo.png';
import backIcon from '../../images/back-arrow.png';
import { vocabulary } from '../../services/vocabulary';

export default function EmailSent() {
  const { menuMovieSelected, GlobalActions, langSelected } =
    useContext(GlobalContext);

  const [lang, setLang] = useState(0);

  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);
  const navigate = useNavigate();
  const navigateBack = () => {
    navigate('/');
  };

  setTimeout(navigateBack, 4000);

  return (
    <div className="login-screen">
      <div className="login-left-part">
        <div className="logo-group-login">
          <img src={logoIcon} alt="Movies icon" className="logo-login" />
        </div>

        <div className="tapper"></div>
      </div>

      <div className="login-container-email-sent">
        <img
          src={backIcon}
          alt="back-icon"
          className="logout-icon"
          onClick={navigateBack}
        />
        <div className="login-box-mail-sent">
          <div className="form-group">
            <div className="input-div">
              <div className="login-header"> {vocabulary.emailSent[lang]}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
