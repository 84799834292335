import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../Components/Layout/Layout';
import { downloadShow,getShowInfo } from '../../api/workFlowApi';
import { useEffect, useState, useRef, useContext } from 'react';
import GlobalContext from '../../GlobalContext';
import { pauseShow, playShow, endShow } from '../../api/workFlowApi';
import qrIcon from '../../images/qr-icon.png';
import closeIcon from '../../images/close-icon.png';
import UserDetails from '../Components/PlayerScreen/UsersDetails';
import QrGenerator from '../Components/ShowsScreen/QrGenerator';
import { vocabulary } from '../../services/vocabulary';

export default function PlayerScreen() {
  const location = useLocation();
  var fileUrl = process.env.REACT_APP_SERVER_API_URL + '/public/player?id=';
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [usersArray, setUsersArray] = useState([]);
  const { state, GlobalActions, langSelected } = useContext(GlobalContext);
  const [videoUrl, setVideoUrl] = useState('');
  const [showUsersDetails, setShowUsersDetails] = useState(false);
  const { blobUrl, show, sessionsQuantity } = location.state;
  const [controlDisbled, setControlDisabled] = useState(false);
  const [qrModal, setQrModal] = useState(false);
  const id = show.showId;
  const videoName = show.videoId.videoName;
  const showName = show.showName;
  const showStartTime = show.showStartTime;

  const [lang, setLang] = useState(0);

  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);

  useEffect(() => {
    const videoStream = async () => {
      try {
        const response = await downloadShow(id);
        if (response.status === 200 || response.status === 206 || true) {
          setVideoUrl(fileUrl + response.data.uuid);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    videoStream();
  }, [id]);


  var interval 
  

  useEffect(() => {


    if (videoRef.current) {
      const handleTimeUpdate = () => {
     
      };
     
     
     
      const handlePlay = async () => {
        if (!controlDisbled) {
          videoRef.current.controls = false;
          setControlDisabled(true);
      
          await playShow({ showId: id, time: videoRef.current.currentTime });
    
         interval = setInterval(() => {
           playShow({ showId: id, time: videoRef.current.currentTime });
          }, 1000);

          videoRef.current.controls = true;
          setControlDisabled(false);
        }
      };

      const handlePause = async () => {
        clearInterval(interval);
        if (!controlDisbled) {
          if (videoRef.current !== null) {
            videoRef.current.controls = false;
            setControlDisabled(true);

            await pauseShow({ showId: id, time: videoRef.current.currentTime });

            setControlDisabled(false);
            videoRef.current.controls = true;
          } else {
            await pauseShow({ showId: id, time: 0 });
          }
        }
      };

      videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
      videoRef.current.addEventListener('play', handlePlay);
      videoRef.current.addEventListener('pause', handlePause);

      return () => {

   
        clearInterval(interval);


      };
    }
  }, [videoUrl]);

  const navigateBack = () => {
    navigate('/shows-list', {
      state: {
        blobUrl: blobUrl,
        id: show.videoId._id,
        videoDuration: show.videoId.videoDuracion,
        videoName,
        sessionsQuantity,
      },
    });
  };

  const emitSubscribeInit = () => {
    let eventSource;

    try {
      let baseUrl =
        process.env.REACT_APP_SERVER_API_URL +
        '/broadcast/sse-admin-init' +
        '?show=' +
        id +
        '&jwt=' +
        state.userToken;

      eventSource = new EventSource(baseUrl);
    
      eventSource.onmessage = async (event) => {
     
        var dataFromApi = event.data;
        if (dataFromApi) {
          setUsersArray(JSON.parse(event.data));
        }
      };

      return () => {
        if (eventSource) {
          eventSource.close();
        }
      };
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const clean =async()=>{
    let data= await getShowInfo(show.showId)
    if(data.currentShowInfo.currentStatus==="ended"){
   navigateBack()
   }
    }
      clean();

   const cleanup = emitSubscribeInit();

    return () => {
      clearInterval(interval);
      cleanup();
    
    };
  }, []);

  const handleShowUsersDetails = () => {
    setShowUsersDetails(!showUsersDetails);
  };



  const handleCloseShow = async () => {
    await endShow({ showId: show.showId });

    navigate('/show-ended', {
      /*it necessary!!!!*/
      state: { blobUrl, show, sessionsQuantity },
    });
  };



  const closeQrModal = () => {
    setQrModal(!qrModal);
  };

  return (
    <Layout
      showLeftpart={true}
      videoName={videoName}
      menuSection={'Películas'}
      showName={showName}
      navigateBack={navigateBack}
      isItShows={true}
     
    >
      <div className="search-div-in-player">
        <div className="text-up-player">
          
            <h4>{showStartTime.slice(0, -6)}
            
            &nbsp; |&nbsp;&nbsp;</h4>
            <div className="pointer-for-mac">
            <div
              className="qr-icon-in-player"
              onClick={() => {
                setQrModal(true);
              }}
            >
              <img src={qrIcon} alt="qr icon" width="15px" height="15px" />
            </div>
            &nbsp;&nbsp;
            <h4 onClick={handleShowUsersDetails} className="show-viewers">
              |&nbsp;&nbsp;{vocabulary.seeParticipants[lang]}: &nbsp;
              {usersArray.length ? usersArray.length : 0} &nbsp;&nbsp;|
              &nbsp;&nbsp;
            </h4>
            <h4 className="close-show" onClick={handleCloseShow}>
              <img
                src={closeIcon}
                alt="close icon"
                width="15px"
                height="15px"
              />
              &nbsp;&nbsp;
              {vocabulary.closeSession[lang]}
            </h4>
          </div>
        </div>
      </div>
      <div className="video-player-main-div">
        <div className="player">
          {videoUrl ? (
            <>
              <video
                ref={videoRef}
                controls
                style={{ width: '100%', height: '100%', objectFit: 'contain',backgroundColor:"black" }}
                autoPlay={false}
              >
                <source src={videoUrl} />
                Your browser does not support the video tag.
              </video>
            </>
          ) : null}
        </div>
      </div>

      {showUsersDetails && (
        <div className="new-video-upload">
          <UserDetails
            usersArray={usersArray}
            handleShowUsersDetails={handleShowUsersDetails}
            lang={lang}
          />
        </div>
      )}

      {qrModal && (
        <div className="new-video-upload">
          {<QrGenerator showId={id} closeQrModal={closeQrModal} lang={lang} />}
        </div>
      )}
    </Layout>
  );
}
