import React, { useEffect, useState } from 'react';
import leftIcon from '../../../images/four-squares.png';
import RightIcon from '../../../images/sort-list.png';

export default function ToggleButtons({ handleSetListView }) {
  const [group, setGroup] = useState(false);
  const handleAlignment = () => {
    setGroup(!group);
    handleSetListView();
  };

  return (
    <div>
      <img
        onClick={handleAlignment}
        style={{ borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }}
        src={leftIcon}
        alt="qr icon"
        width="21px"
        height="21px"
        className={!group ? 'set-active' : 'set-inactive'}
      />
      <img
        onClick={handleAlignment}
        style={{ borderTopRightRadius: '5px', borderBottomRightRadius: '5px' }}
        src={RightIcon}
        alt="qr icon"
        width="21px"
        height="21px"
        className={group ? 'set-active' : 'set-inactive'}
      />
    </div>
  );
}
