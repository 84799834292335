import GlobalContext from '../../GlobalContext';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import React, { useContext, useEffect, useState } from 'react';
import backIcon from '../../images/back-arrow.png';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import GoogleAuth from './GoogleAuth';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import logoIcon from './../../images/logo.png';
import { vocabulary } from '../../services/vocabulary';
import DeleteVideo from '../Components/VideosScreen/DeleteVideo';
import TermsAndConditions from './TermsAndConditions';

function SignUp() {
  const { GlobalActions, langSelected } = useContext(GlobalContext);
  const [email, setAdminEmail] = useState('');
  const [password, setUserPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showEmailErrorMessage, setShowEmailErrorMessage] = useState(false);
  const [showSignUpError, setShowSignUpError] = useState(false);
  const [showCodeError, setShowCodeError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [lang, setLang] = useState(0);
  const [code, setCode] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {


    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === '' || showEmailErrorMessage) {
      setShowEmailErrorMessage(true);
      return;
    }
    setShowSignUpError(false);
    setShowCodeError(false);

    if (password === confirmPassword) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);

      return;
    }
    let codeToSend = code;

    if (code === '') {
      codeToSend = 'baaad';
    }

    let res = await GlobalActions.signUp({
      email,
      password,
      userName,
      surname: '',
      gender: '',
      age: '',
      code: codeToSend,
      userType: 'admin',
    });

    debugger;
    if (res === 'User already exists.') {
      setShowSignUpError(true);
      return;
    }
    if (res === 'Code incorrect.') {
      setShowCodeError(true);
      return;
    }

    if (res === true) {
      navigate('/email-sent');
    }
  };

  const navigateBack = () => {
    navigate('/');
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (emailRegex.test(email) || email === '') {
      setAdminEmail(email);
      setShowEmailErrorMessage(false);
    } else {
      setAdminEmail(email);
      setShowEmailErrorMessage(true);
    }
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setUserPassword(password);
    setPasswordsMatch(true);

    const consecutiveRepeatingRegex = /(.)\1{2,}/;

    if (password.length < 6) {
      setPasswordError(vocabulary.passwordLongitudError[lang]);
    } else if (consecutiveRepeatingRegex.test(password)) {
      setPasswordError(vocabulary.passwordRepeatSymbolsError[lang]);
    } else {
      setPasswordError('');
    }
  };
  const handleSetShowSignUpError = (val) => {
    setShowSignUpError(val);
  };
  const handleConfirmPasswordChange = (event) => {
    setPasswordsMatch(true);
    setConfirmPassword(event.target.value);
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleCloseClick = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <div className="login-screen">
      <div className="login-left-part">
        {modalOpen && (
          <div className="terms-and-conditions">
            <TermsAndConditions
              handleCloseClick={handleCloseClick}
              lang={lang}
            />
          </div>
        )}
        <div className="logo-group-login">
          <img src={logoIcon} alt="Movies icon"  height={"150px"} width={"200px"} />
        </div>
      </div>

      <div className="login-container">
        <div className="login-box">
          <form className="form-login" onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="input-div-login-text"></div>

              <div className="input-div-login-text">
                <img
                  src={backIcon}
                  alt="back-icon"
                  className="login-icon-back"
                  onClick={navigateBack}
                />
                <div className="login-header">{vocabulary.register[lang]}</div>
              </div>

              <div className="input-div">
                <TextField
                  variant="outlined"
                  autoComplete="username"
                  fullWidth
                  margin="normal"
                  placeholder={vocabulary.namesAndSurnames[lang]}
                  name="userName"
                  value={userName}
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className="input-div">
                <TextField
                  variant="outlined"
                  autoComplete="email"
                  fullWidth
                  margin="normal"
                  placeholder={vocabulary.email[lang]}
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                {showEmailErrorMessage && (
                  <div className="error-message">
                    {vocabulary.emailErrorBlank[lang]}
                  </div>
                )}
              </div>

              <div className="input-div">
                <TextField
                  variant="outlined"
                  style={{ color: 'white' }}
                  fullWidth
                  margin="normal"
                  placeholder={vocabulary.password[lang]}
                  name="password"
                  value={password}
                  autoComplete="current-password"
                  onChange={handlePasswordChange}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePassword} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {passwordError && (
                  <div className="error-message">{passwordError}</div>
                )}
              </div>

              <div className="input-div">
                <TextField
                  variant="outlined"
                  style={{ color: 'white' }}
                  fullWidth
                  margin="normal"
                  placeholder={vocabulary.confirmPassword[lang]}
                  name="confirm-password"
                  value={confirmPassword}
                  autoComplete="current-password"
                  onChange={handleConfirmPasswordChange}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePassword} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {!passwordsMatch && (
                  <div className="error-message">
                    {vocabulary.passwordDoesNotMatch[lang]}
                  </div>
                )}
              </div>
              <div className="input-div">
                <TextField
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder={vocabulary.enterCode[lang]}
                  name="code"
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                />
              </div>

              <div
                className="input-div"
                style={{ display: 'flex', alignItems: 'flex-end' }}
              >
                <div
                  className="start-with-google-signup"
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  {vocabulary.termsAndConditions[lang]}
                </div>
              </div>
              <div className="input-div">
                {showSignUpError && (
                  <div className="error-message-code">
                    {vocabulary.emailError[lang]}
                  </div>
                )}
                {showCodeError && (
                  <div className="error-message-code">
                    {vocabulary.incorrectCode[lang]}
                  </div>
                )}
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    width: '100%',
                    height: '50px',
                    marginTop: '20px',
                    backgroundColor: '#0D1C28',
                    color: 'white',
                  }}
                  fullWidth
                  margin="normal"
                  onClick={handleSubmit}
                >
                  {vocabulary.register[lang]}
                </Button>
              </div>

              <div
                className="input-div"
                style={{ display: 'flex', alignItems: 'flex-end' }}
              >
                <GoogleAuth
                  setShowSignUpError={handleSetShowSignUpError}
                  code={code}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
