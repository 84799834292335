import Layout from '../Components/Layout/Layout.js';
import { getAllVideos, getAllShows, getImage } from '../../api/workFlowApi.js';
import { useContext, useState, useEffect } from 'react';
import NewShow from '../Components/ShowsScreen/NewShow.js';
import ShowCardItem from '../Components/ShowsScreen/ShowCardItem.js';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import QrGenerator from '../Components/ShowsScreen/QrGenerator.js';
import sortIcon from '../../images/sort-icon.png';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import RenameShow from '../Components/ShowsScreen/RenameShow.js';
import DeleteShow from '../Components/ShowsScreen/DeleteShow.js';
import ToggleButtons from '../Components/VideosScreen/ToggleButtons';
import ShowListItem from '../Components/ShowsScreen/ShowListItem.js';
import sortDateIcon from '../../images/sort-date.png';
import GlobalContext from '../../GlobalContext';

import { vocabulary } from '../../services/vocabulary';

export default function ShowsScreen() {
  const { langSelected, state } = useContext(GlobalContext);

  const [lang, setLang] = useState(0);

  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);

  const location = useLocation();
  const { id, videoDuration, videoName, sessionsQuantity } = location.state;
  const [qRId, setQRId] = useState(null);
  const [showsArray, setShowsArray] = useState([]);
  const [shows, setShows] = useState([]);
  const [showNewShow, setShowNewShow] = useState(false);
  const [changeShow, setChangeShow] = useState(false);
  const [showNumber, setShowNumber] = useState(sessionsQuantity);
  const [showForChange, setShowForChange] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();
  const [sortUp, setSortUp] = useState(true);
  const [userWantToDeleteShow, setUserWantToDeleteShow] = useState(false);
  const [showsToDelete, setShowsToDelete] = useState(null);
  const [startDate, setStartDate] = useState(getFormattedDate("2023-01-01"));
  const [endDate, setEndDate] = useState(getFormattedDateNow());
  const [referredArray, setReferredArray] = useState([]);
  const [listView, setListView] = useState(true);

  const [blobUrl, setBlobURL] = useState(null);

  async function onLoadGet() {
    try {
      let result = await getAllShows(id);
      setShowsArray(result);
      setShows(result);
      setShowNewShow(false);
      setReferredArray(result);
    } catch (error) {
      console.error('Error fetching shows:', error);
    }
    let image = await getImage(id);

    if (image) {
      let bufferObject = JSON.parse(image.data[0].blob);
      const uint8Array = new Uint8Array(bufferObject.data);
      let blob = new Blob([uint8Array], { type: bufferObject.type });
      setBlobURL(URL.createObjectURL(blob));
    }
  }

  function getFormattedDate(dateString) {
    const currentDate = new Date(dateString);
    return currentDate.toISOString().split('T')[0];
  }
  function getFormattedDateNow() {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 6);
    return currentDate.toISOString().split('T')[0];
  }

  const handleSortDate = () => {
    let arr = [...shows];

    arr.sort((a, b) => {
      const videoNameA = new Date(b.createdAt);
      const videoNameB = new Date(a.createdAt);
      if (videoNameA < videoNameB) {
        return sortUp ? -1 : 1;
      }
      if (videoNameA > videoNameB) {
        return sortUp ? 1 : -1;
      }
      return 0;
    });
    setSortUp(!sortUp);

    setShows(arr);
    setReferredArray(arr);
  };

  useEffect(() => {
    onLoadGet();
  }, []);

  const onClickDeleteShow = (id) => {


    let newArray = showsArray.filter((val) => {
      if (val.showId !== id) {
        return val;
      }
    });
    setShowsArray(newArray);
    setShows(newArray);
  };

  const newShowOnClick = () => {
    setShowNewShow(!showNewShow);
  };
  const navigateBack = () => {
    navigate('/');
  };

  const numberShowIncrease = () => {
    setShowNumber(showNumber + 1);
  };
  const numberShowDecrease = () => {
    setShowNumber(showNumber - 1);
  };
  const showQR = (showId) => {
    setQRId(showId);
  };

  const closeQrModal = () => {
    setQRId(null);
  };

  const handleSearch = (e) => {
    let compare = e.target.value;
    setSearchValue(compare);
    if (compare !== '') {
      let result = showsArray.filter((element) => {
        if (element.showName.toLowerCase().includes(compare.toLowerCase())) {
          return element;
        }
      });
      setShows(result);
      setReferredArray(result);
    } else {
      setShows(showsArray);
      setReferredArray(showsArray);
    }
  };

  const handleChangeShow = (show) => {
    setShowForChange(show);
    setChangeShow(!changeShow);
  };
  const handleSort = () => {
    let arr = [...shows];

    arr.sort((a, b) => {
      const videoNameA = a.showName.toLowerCase();
      const videoNameB = b.showName.toLowerCase();
      if (videoNameA < videoNameB) {
        return sortUp ? -1 : 1;
      }
      if (videoNameA > videoNameB) {
        return sortUp ? 1 : -1;
      }
      return 0;
    });
    setSortUp(!sortUp);
    setShows(arr);
    setReferredArray(arr);
  };
  const showAllShow = () => {
    setShows(showsArray);
    setSearchValue('');
  };

  const HandleDeleteClick = (id) => {
    setShowsToDelete(id);
    setUserWantToDeleteShow(true);
  };

  const onClickCloseDeleteShow = () => {
    setUserWantToDeleteShow(!userWantToDeleteShow);
  };

  useEffect(() => {
    let arr = [...referredArray];
    let downDate =
      startDate !== '' ? new Date(startDate) : new Date('01-01-1980');
    let upDate = endDate !== '' ? new Date(endDate) : new Date('01-01-3000');
    let result = arr.filter((val) => {
      let showDate = new Date(val.showStartTime);
      if (showDate > downDate && showDate < upDate) {
        return val;
      }
    });

    let isTheSame = JSON.stringify(shows);
    let compare = JSON.stringify(result);
    if (compare !== isTheSame) {
      setShows(result);
    }
  }, [startDate, endDate, shows]);

  const handleSetListView = () => {
    setListView(!listView);
  };

  return (
    <Layout
      showLeftPart={true}
      videoName={videoName}
      menuSection={'Películas'}
      navigateBack={navigateBack}
      isItShows={true}
    >
      <div className="main-div">
        <div className="search-div-in-main">
          <div className="search-box-and-sort">
            <TextField
              variant="outlined"
              size="small"
              margin="dense"
              placeholder={vocabulary.sessionSearch[lang]}
              name="search-box"
              value={searchValue}
              onChange={handleSearch}
              sx={{
                marginTop: '3px',
                marginLeft: '10px',
                background: 'black',
                borderRadius: '5px',
                color: '',
                input: {
                  color: 'white',
                  fontSize: 'smaller',
                  '& ~ .MuiInputAdornment-root': {
                    backgroundColor: 'black',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: '#9E9E9E' }} />
                  </InputAdornment>
                ),
              }}
            />
            <div className="text-in-search">{vocabulary.fromDate[lang]}</div>
            <input
              className="input-login-date"
              type="date"
              id="start-date"
              value={startDate}
              placeholder="DD/MM/AAAA"
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
              style={{ maxWidth: '200px' }}
            />
            <div className="text-in-search">{vocabulary.tillDate[lang]}</div>
            <input
              className="input-login-date"
              type="date"
              id="age"
              placeholder="DD/MM/AAAA"
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
              value={endDate}
              style={{ maxWidth: '200px' }}
            />

            <img
              onClick={handleSort}
              src={sortIcon}
              alt="back-icon"
              className="sort-img"
            />
            <img
              onClick={handleSortDate}
              src={sortDateIcon}
              alt="back-icon"
              className="sort-img"
            />
          </div>
          <div className="sort-box">
            <ToggleButtons handleSetListView={handleSetListView} />
          </div>
        </div>
        {listView ? (
          <div className="sub-main-div">
            <div className="new-show" onClick={newShowOnClick}>
              <div className="plus-sign"> + </div>
              <div className="text-inside-new-video">
                {vocabulary.addSession[lang]}
              </div>
            </div>

            {shows.map((show) => (
              <ShowCardItem
                key={show._id}
                show={show}
                blobUrl={blobUrl}
                HandleDeleteClick={HandleDeleteClick}
                sessionsQuantity={showNumber}
                videoId={show.videoId._id}
                showQR={showQR}
                handleChangeShow={handleChangeShow}
                lang={lang}
              />
            ))}

            {showNewShow && (
              <div className="new-video-upload">
                {
                  <NewShow
                    onLoadGet={onLoadGet}
                    newShowOnClick={newShowOnClick}
                    videoDuration={videoDuration}
                    videoId={id}
                    blobUrl={blobUrl}
                    sessionsQuantity={showNumber}
                    numberShowIncrease={numberShowIncrease}
                    lang={lang}
                  />
                }
              </div>
            )}
            {qRId && (
              <div className="new-video-upload">
                {
                  <QrGenerator
                    showId={qRId}
                    closeQrModal={closeQrModal}
                    lang={lang}
                  />
                }
              </div>
            )}
            {changeShow && (
              <div className="new-video-upload">
                <RenameShow
                  onLoadGet={onLoadGet}
                  showForChange={showForChange}
                  handleChangeShow={handleChangeShow}
                  lang={lang}
                />
              </div>
            )}
            {userWantToDeleteShow && (
              <div className="new-video-upload">
                <DeleteShow
                  onClickCloseDeleteShow={onClickCloseDeleteShow}
                  onClickDeleteShow={onClickDeleteShow}
                  id={showsToDelete}
                  numberShowDecrease={numberShowDecrease}
                  sessionsQuantity={showNumber}
                  videoId={id}
                  onLoadGet={onLoadGet}
                  lang={lang}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="sub-main-div-list">
            <div className="new-video-list" onClick={newShowOnClick}>
              <div className="plus-sign"> + </div>
              <div className="text-inside-new-video-list">
                {vocabulary.addSession[lang]}
              </div>
            </div>

            {shows.map((show) => (
              <ShowListItem
                key={show._id}
                show={show}
                blobUrl={blobUrl}
                HandleDeleteClick={HandleDeleteClick}
                sessionsQuantity={showNumber}
                videoId={show.videoId._id}
                showQR={showQR}
                handleChangeShow={handleChangeShow}
                lang={lang}
              />
            ))}

            {showNewShow && (
              <div className="new-video-upload">
                {
                  <NewShow
                    onLoadGet={onLoadGet}
                    newShowOnClick={newShowOnClick}
                    videoDuration={videoDuration}
                    videoId={id}
                    blobUrl={blobUrl}
                    sessionsQuantity={showNumber}
                    numberShowIncrease={numberShowIncrease}
                    lang={lang}
                  />
                }
              </div>
            )}
            {qRId && (
              <div className="new-video-upload">
                {
                  <QrGenerator
                    showId={qRId}
                    closeQrModal={closeQrModal}
                    lang={lang}
                  />
                }
              </div>
            )}
            {changeShow && (
              <div className="new-video-upload">
                <RenameShow
                  onLoadGet={onLoadGet}
                  showForChange={showForChange}
                  handleChangeShow={handleChangeShow}
                  lang={lang}
                />
              </div>
            )}
            {userWantToDeleteShow && (
              <div className="new-video-upload">
                <DeleteShow
                  onClickCloseDeleteShow={onClickCloseDeleteShow}
                  onClickDeleteShow={onClickDeleteShow}
                  id={showsToDelete}
                  numberShowDecrease={numberShowDecrease}
                  sessionsQuantity={showNumber}
                  videoId={id}
                  onLoadGet={onLoadGet}
                  lang={lang}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
}
