import React, { useEffect, useState } from 'react';
import closeIcon from '../../../images/close-icon.png';
import warningTriangle from '../../../images/warning-triangle.png';
import { deleteVideo } from '../../../api/workFlowApi';
import Button from '@mui/material/Button';
import { deleteShow, startShow } from '../../../api/workFlowApi';
import { vocabulary } from '../../../services/vocabulary';

export default function DeleteShow({
  onClickCloseDeleteShow,
  onLoadGet,
  id,
  numberShowDecrease,
  sessionsQuantity,
  videoId,
  onClickDeleteShow,
  lang,
}) {
  const onClickDelete = async () => {
    await deleteShow({
      showId: id,
      sessionsQuantity: sessionsQuantity,
      videoId: videoId,
    });
    numberShowDecrease();
    onClickDeleteShow(id);
  };

  const handleDeleteSubmit = async () => {
    await onClickDelete();
    onClickCloseDeleteShow();
    onLoadGet();
  };

  return (
    <div className="new-video-modal">
      <div className="delete-video-form">
        <div className="header-delete-video">
          <div className="text-inside-div-header-delete-video">
            {vocabulary.deleteSession[lang]}
          </div>
          <div
            className="new-video-add-close-icon"
            onClick={onClickCloseDeleteShow}
          >
            <img src={closeIcon} alt="Movies icon" className="menu-icons" />
          </div>
        </div>
        <div className="delete-element-text">
          <img
            src={warningTriangle}
            alt="warning icon"
            width={'88px'}
            height={'76px'}
          />
          <h4>{vocabulary.areYouSureToDeleteSession[lang]}</h4>
        </div>

        <div className="delete-line" />
        <div className="button-block-delete">
          <div className="time-date-set-text">
            <Button
              variant="outlined"
              color="primary"
              style={{
                width: '100%',
                height: '50px',
                marginTop: '20px',
                borderColor: '#9e9e9e',
                color: '#0D1C28',
              }}
              fullWidth
              margin="normal"
              onClick={onClickCloseDeleteShow}
            >
              {vocabulary.cancel[lang]}
            </Button>
          </div>

          <div className="time-date-set-text">
            <Button
              variant="outlined"
              color="primary"
              style={{
                width: '100%',
                height: '50px',
                marginTop: '20px',
                backgroundColor: 'black',
                color: 'white',
              }}
              fullWidth
              margin="normal"
              onClick={handleDeleteSubmit}
            >
              {vocabulary.deleteSession[lang]}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
