import { useRef, useState } from 'react';
import { deleteShow, startShow } from '../../../api/workFlowApi';

import threePointsIcon from '../../../images/three-points-icon.png';

import { useNavigate } from 'react-router-dom';
import ContextMenuShowList from './ContextMenuShowList';
import { vocabulary } from '../../../services/vocabulary';

export default function ShowListItem({
  show,
  blobUrl,
  sessionsQuantity,
  showQR,
  handleChangeShow,
  HandleDeleteClick,
  lang,
}) {
  const [showContext, setShowContext] = useState(false);

  const navigate = useNavigate();
  const isEnded = show.currentStatus === 'ended';
  const onClickStart = async () => {
    await startShow({ showId: show.showId });
    navigate('/player', {
      state: { blobUrl, show, /*it necessary!!!!*/ sessionsQuantity },
    });
  };

  const handleShowQR = () => {
    showQR(show.showId);
  };
  const onclickStats = () => {
  
    navigate('/show-ended', {
      /*it necessary!!!!*/
      state: { blobUrl, show, sessionsQuantity },
    });
  };
  const onClickChange = () => {
    handleChangeShow(show);
  };

  function handleClickOutside(event) {
    if (
      targetElementRef.current &&
      !targetElementRef.current.contains(event.target)
    ) {
      document.removeEventListener('click', handleClickOutside);
      setShowContext(false);
    }
  }
  const targetElementRef = useRef(null);

  const addClickListener = () => {
    document.addEventListener('click', handleClickOutside);
  };

  const onClickContextMenu = () => {
    setShowContext(!showContext);

    if (!showContext) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
  };

  const onClickDelete = () => {
    HandleDeleteClick(show.showId);
  };

  return (
    <div key={show._id} className="videos-item-list">
      <div className="video-text-block-above-list" ref={targetElementRef}>
        <div style={{ width: '15%' }}>
          <div
            className={
              !isEnded
                ? 'image-preview-div-list'
                : 'image-preview-div-list-ended'
            }
          >
            {blobUrl ? (
              <img
                className="image-preview-video-list"
                src={blobUrl}
                alt={`Image preview`}
              />
            ) : null}
          </div>
        </div>
        <div style={{ width: '35%' }}>
          {show?.showName?.toUpperCase().substring(0, 26)}
        </div>
        {isEnded ? (
          <div style={{ color: '#fe1a79', width: '20%' }}>
            {vocabulary.sessionEnded[lang]}
          </div>
        ) : (
          <div style={{ width: '20%' }}>
            {vocabulary.sessionProgrammed[lang]}
          </div>
        )}

        <div style={{ width: '20%' }}>
          {vocabulary.session[lang]}:&nbsp;{show?.showStartTime}
        </div>

        <div
          className="three-points-block-list-show"
          style={{ width: '10%' }}
          ref={targetElementRef}
        >
          <img
            src={threePointsIcon}
            alt="three-points-icon"
            className="three-points-icon"
            onClick={onClickContextMenu}
          />
          {showContext && (
            <ContextMenuShowList
              isEnded={isEnded}
              onClickStart={onClickStart}
              onclickStats={onclickStats}
              handleShowQR={handleShowQR}
              onClickChange={onClickChange}
              onClickDelete={onClickDelete}
              addClickListener={addClickListener}
              lang={lang}
            />
          )}
        </div>
      </div>
    </div>
  );
}
