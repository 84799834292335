import { useContext, useEffect, useState } from 'react';
import Layout from '../Components/Layout/Layout';
import GlobalContext from '../../GlobalContext';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import {
  changeUserInformation,
  getUserInformation,
} from '../../api/workFlowApi';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { vocabulary } from '../../services/vocabulary';

export default function UserMenu() {
  const [showPassword, setShowPassword] = useState(false);
  const { GlobalActions } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [passwordError, setPasswordError] = useState(false);
  const [formData, setFormData] = useState({
    userName: '',
    password: '',
    email: '',
    passwordRepeat: '',
    surname: '',
    phoneNumber: '',
    address: '',
    occupation: '',
    civilState: '',
    birthDate: '',
    city: '',
    gender: '',
    educationLevel: '',
    interests: '',
  });
  const getInfo = async () => {
    let info = await getUserInformation();

    let data = info.data.userData;
    setFormData({
      ...formData,
      userName: data.userName ? data.userName : '',
      email: data.email,
      surname: data.surname ? data.surname : '',
      phoneNumber: data.phoneNumber ? data.phoneNumber : '',
      address: data.address ? data.address : '',
      occupation: data.occupation ? data.occupation : '',
      civilState: data.civilState ? data.civilState : '',
      birthDate: data.birthDate ? data.birthDate : '',
      city: data.city ? data.city : '',
      gender: data.gender ? data.gender : '',
      educationLevel: data.educationLevel ? data.educationLevel : '',
      interests: data.interests ? data.interests : '',
    });
  };
  useEffect(() => {
    GlobalActions.handleMenuClick(false);
    getInfo();
  }, []);

  const handleClickBack = () => {
    navigate('/');
  };

  const { langSelected, state } = useContext(GlobalContext);

  const [lang, setLang] = useState(0);

  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);

  const handleChange = (e) => {
    setPasswordError(false);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  /*
  const handleFileUpload = async (selectedFile) => {
    
    
    let randomID = generateRandom();
    setIsLoadFile(true);
    try {
      let newVideo = await postNewUserImage({
        imageId: randomID,
        file: selectedFile,
      });

      if (newVideo === 'error') {
        setErrorShow(true);
      } else {
     
        setSelectedFile(null);
        setIsLoadFile(false);
     
     
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

*/

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.password === formData.passwordRepeat) {
      delete formData.passwordRepeat;
      //checking if password exist
      if (!formData.password?.length > 0) {
        delete formData.password;
      }

      changeUserInformation(formData);
      GlobalActions.handleMenuClick(true);
      handleClickBack();
    } else {
      setPasswordError(true);
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <Layout showLeftpart={true}>
      <div className="admin-menu">
    

        <div className="admin-info-changed-camps">
          <div className="admin-info-column">
            <form>
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                placeholder={vocabulary.userName[lang]}
                name="userName"
                value={formData.userName}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                placeholder={vocabulary.email[lang]}
                name="email"
                value={formData.email}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                variant="outlined"
                autoComplete="no"
                fullWidth
                margin="normal"
                placeholder={vocabulary.password[lang]}
                name="password"
                value={formData.password}
                onChange={handleChange}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={handleTogglePassword} edge="start">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div className="space-in-admin-info" />
              <TextField
                variant="outlined"
                value={formData.phoneNumber}
                fullWidth
                margin="normal"
                placeholder={vocabulary.phoneNumber[lang]}
                name="phoneNumber"
                onChange={handleChange}
              />
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                placeholder={vocabulary.direction[lang]}
                name="address"
                onChange={handleChange}
                value={formData.address}
              />
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                placeholder={vocabulary.occupation[lang]}
                name="occupation"
                onChange={handleChange}
                value={formData.occupation}
              />
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                placeholder={vocabulary.civilStatus[lang]}
                name="civilState"
                onChange={handleChange}
                value={formData.civilState}
              />
            </form>
          </div>
          <div className="admin-info-column">
            <form>
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                placeholder={vocabulary.surname[lang]}
                name="surname"
                value={formData.surname}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant="outlined"
                fullWidth
                type="date"
                margin="normal"
                name="birthDate"
                onChange={handleChange}
                value={formData.birthDate}
              />
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                name="passwordRepeat"
                placeholder={vocabulary.confirmPassword[lang]}
                autoComplete="no"
                value={formData.passwordRepeat}
                onChange={handleChange}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={handleTogglePassword} edge="start">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div className="space-in-admin-info">
                {passwordError && (
                  <div className="error-message">
                    {'Las contraseñas no coinciden'}
                  </div>
                )}
              </div>
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                placeholder={vocabulary.city[lang]}
                name="city"
                onChange={handleChange}
                value={formData.city}
              />
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                placeholder={vocabulary.gender[lang]}
                name="gender"
                onChange={handleChange}
                value={formData.gender}
              />
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                placeholder={vocabulary.educationLevel[lang]}
                name="educationLevel"
                onChange={handleChange}
                value={formData.educationLevel}
              />
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                placeholder={vocabulary.interest[lang]}
                name="interests"
                onChange={handleChange}
                value={formData.interests}
                sx={{
                  background: 'white',
                  borderRadius: '10px',
                  input: {
                    color: 'gray',

                    '& ~ .MuiInputAdornment-root': {
                      backgroundColor: '#fff',
                    },
                  },
                }}
              />
              <Button
                variant="outlined"
                color="primary"
                style={{
                  width: '100%',
                  height: '50px',
                  marginTop: '20px',
                  backgroundColor: 'black',
                  color: 'white',
                }}
                fullWidth
                margin="normal"
                onClick={handleSubmit}
              >
                {vocabulary.updateAccount[lang]}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}
