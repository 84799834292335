import FilledInput from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import { postChatMessage } from '../../../api/workFlowApi';
import { useState, useEffect, useContext, useRef } from 'react';
import formatTime from '../../../helpers/formatTime';
import GlobalContext from '../../../GlobalContext';
import userIcon from '../../../images/person-icon.png';
import aiIcon from '../../../images/logo-color.png';
export default function ChatWindow({ handleShowChat, data }) {
  const { state, GlobalActions } = useContext(GlobalContext);
  const [userQuestion, setUserQuestion] = useState('');
  const [chatAnswer, setChatAnswer] = useState('');
  const [chatFlow, setChatFlow] = useState([]);
  let dataToSend = data.map((val) => {
    return {
      name: val.userId.userName,
      time: formatTime(val.time),
      score: val.score ? 'bien' : 'mal',
      annotacion: val.annotation,
    };
  });
  useEffect(() => {
    if (state?.chatFlow) {
      setChatFlow(state.chatFlow);
    }
  }, []);
  const handleSendClick = async () => {
    setUserQuestion('');
    const data = { comments: dataToSend, userInput: userQuestion };
    let arr = [...chatFlow];
    arr.push({ question: userQuestion, answer: '...' });
    setChatFlow(arr);
    let result = await postChatMessage(data);
    setChatAnswer(result.botResponse);
    arr[arr.length - 1].answer = result.botResponse;
    setChatFlow(arr);
    GlobalActions.setChatFlow(arr);
  };

  const scrollContainerRef = useRef();

  useEffect(() => {
    scrollContainerRef.current.scrollTop =
      scrollContainerRef.current.scrollHeight;
  });
  return (
    <div className="chat-window-component">
      <div className="header-chat" onClick={handleShowChat}>
        <h3>Chat online</h3>
        <h3>&#10540;</h3>
      </div>
      <div className="chat-flow" ref={scrollContainerRef}>
        {chatFlow.map((val, index) => {
          return (
            <div style={{marginLeft:"10px", marginRight:"5px"}} key={index}>
              <div className="user-questions-in-chat">
                <img src={userIcon} width="15px"  />
                <h5>{state.userInfo?.userName}: </h5>
            
             </div>
          <div className='chat-answer'>   {val.question}</div>
              <div className="user-questions-in-chat">
     
                <img
                  src={aiIcon}
                  alt="person icon"
                  width="15px"
                />
                <h5>Screenings: </h5>
              
              </div>
              <div className='chat-answer'>{val.answer}</div>
            </div>
          );
        })}
      </div>

      <FilledInput
        variant="outlined"
        fullWidth
        margin="normal"
        placeholder="Escribe tu pregunta"
        color="primary"
        value={userQuestion}
        onChange={(e) => {
          setUserQuestion(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleSendClick();
          }
        }}
        sx={{
          background: 'white',
          borderRadius: '10px',
          input: {
            color: 'black',

            '& ~ .MuiInputAdornment-root': {
              backgroundColor: '#fff',
            },
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              onClick={handleSendClick}
              sx={{
                background: 'white',
                borderRadius: '10px',
                cursor: 'pointer',
              }}
            >
              <SendSharpIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
