import { useRef, useState } from 'react';
import { deleteShow, startShow } from '../../../api/workFlowApi';

import threePointsIcon from '../../../images/three-points-icon.png';

import { useNavigate } from 'react-router-dom';
import ContextMenuShow from './ContextMenuShow';
import { vocabulary } from '../../../services/vocabulary';

export default function ShowCardItem({
  show,
  blobUrl,
  sessionsQuantity,
  showQR,
  handleChangeShow,
  HandleDeleteClick,
  lang,
}) {
  const [showContext, setShowContext] = useState(false);

  const navigate = useNavigate();
  const isEnded = show.currentStatus === 'ended';
  const onClickStart = async () => {
    await startShow({ showId: show.showId });
    navigate('/player', {
      state: { blobUrl, show, /*it necessary!!!!*/ sessionsQuantity },
    });
  };

  const handleShowQR = () => {
    showQR(show.showId);
  };
  const onclickStats = () => {
  
    navigate('/show-ended', {
      /*it necessary!!!!*/
      state: { blobUrl, show, sessionsQuantity },
    });
  };
  const onClickChange = () => {
    handleChangeShow(show);
  };

  function handleClickOutside(event) {
    if (
      targetElementRef.current &&
      !targetElementRef.current.contains(event.target)
    ) {
      document.removeEventListener('click', handleClickOutside);
      setShowContext(false);
    }
  }
  const targetElementRef = useRef(null);

  const addClickListener = () => {
    document.addEventListener('click', handleClickOutside);
  };

  const onClickContextMenu = () => {
    setShowContext(!showContext);

    if (!showContext) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
  };

  const onClickDelete = () => {
    HandleDeleteClick(show.showId);
  };

  return (
    <div key={show._id} className="shows-item">
      <div
        className="image-preview-div-show"
        onClick={() => {
          if (!isEnded) {
            onClickStart();
          }
        }}
      >
        {blobUrl ? (
          <img
            className={
              isEnded ? 'image-preview-video-show' : 'image-preview-video'
            }
            src={blobUrl}
            alt={`Image `}
            onClick={onclickStats}
          />
        ) : null}
        <div className="show-name-on-show-details"></div>
      </div>
      <div
        className={!isEnded ? 'show-text-block' : 'show-text-block-above-ended'}
      >
        <div
          className="video-info"
          onClick={() => {
            if (!isEnded) {
              onClickStart();
            }
          }}
        >
          <div className="video-name">
            {show?.showName?.toUpperCase().substring(0, 16)}
          </div>

          <div className="video-name-ended">
            {isEnded ? vocabulary.sessionEnded[lang] : null}
          </div>

          <div className="show-quantity">
            {vocabulary.session[lang]} :&nbsp;{show?.showStartTime}
          </div>
        </div>
        <div ref={targetElementRef} className="three-points-block">
          <img
            src={threePointsIcon}
            alt="three-points-icon"
            className="three-points-icon"
            onClick={onClickContextMenu}
          />
          {showContext && (
            <ContextMenuShow
              isEnded={isEnded}
              onClickStart={onClickStart}
              onclickStats={onclickStats}
              handleShowQR={handleShowQR}
              onClickChange={onClickChange}
              onClickDelete={onClickDelete}
              addClickListener={addClickListener}
              lang={lang}
            />
          )}
        </div>
      </div>
    </div>
  );
}
