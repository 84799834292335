import serviceAxios from './serviceAxios';

export async function getAdminInfo() {
  try {
    const response = await serviceAxios.get('/private/get-user-admin-info');

    return response.data;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}

export async function postNewShow(data) {
  try {
    const response = await await serviceAxios.post('/private/new-show', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });



    return response.data;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}
export async function postNewVideo(data, { onUploadProgress }) {
  try {
    const formData = new FormData();

    formData.append('file', data.file);
    formData.append('videoId', data.videoId);
    formData.append('videoName', data.videoName);
    const response = await serviceAxios.post('/private/new-video', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },

      onUploadProgress: (progressEvent) => {
        const progress = parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
        // Update state here
        onUploadProgress(progress);
      },
    });

  

    return response.data;
  } catch (error) {
    console.error('An error occurred:', error);
    return 'error';
  }
}

export async function deleteShow(data) {
  try {
    const response = await serviceAxios.post('/private/delete-show', data);
  
    return response.data;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}

export async function deleteVideo(data) {
  try {
    const response = await serviceAxios.post('/private/delete-video', data);

    return response.data;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}
export async function getShowInfo(data) {
  try {
    const response = await serviceAxios.get('/private/get-show-information/?id=' + data);
  
    return response.data;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}





export async function getAllShows(data) {
  try {
    const response = await serviceAxios.get('/private/shows-get?id=' + data);
    const result = response.data;

    return result;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}
export async function getImage(data) {
  try {
    const response = await serviceAxios.get('/private/get-image?id=' + data);

    if (response.status !== 200) {
      console.error('[!] Error:', response.status, 'returned');
      throw new Error(`Request failed with status ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error('[!] Error:', error);

    return error.response.data.message;
  }
}

export async function getAllVideos() {
  try {
    const response = await serviceAxios.get('/private/videos-get');
    const result = response.data;

    return result;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}

export async function startShow(data) {
  try {
  

    const response = await serviceAxios.post('/broadcast/start-show', data);
    const result = response.data;

    return result;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}
export async function playShow(data) {
  try {
    const response = await serviceAxios.post('/broadcast/play-show', data);
    const result = response.data;

    return result;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}
export async function pauseShow(data) {

  try {
    const response = await serviceAxios.post(
      '/broadcast/pause-resume-show',
      data
    );
    const result = response.data;

    return result;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}

export async function endShow(data) {
  try {
    const response = await serviceAxios.post('/broadcast/end-show', data);
    const result = response.data;

    return result;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}

export async function downloadShow(data) {
  try {
    const response = await serviceAxios.get('/private/player?id=' + data);

    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}
export async function getStatisticsShow(data) {
  try {
    const response = await serviceAxios.get(
      '/private/get-statistic?id=' + data
    );

    return response.data;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}

export async function setStartShowTime(data) {
  try {
    const response = await serviceAxios.post(
      '/private/set-show-start-time',
      data
    );
    
    return response.data;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}
export async function changeVideoName(data) {
  try {
    const response = await serviceAxios.post('/private/set-video-name', data);

    return response.data;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}

export async function changeShowName(data) {
  try {
    const response = await serviceAxios.post('/private/set-show-name', data);
  
    return response.data;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}

export async function changeVote(data) {

  try {
    const response = await serviceAxios.post('/private/change-vote', data);
    const result = response.data;

    return result;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}

export async function getUserInformation() {
  try {
    const response = await serviceAxios.get(`/private/get-user-information`);

    if (response.status !== 200) {
      console.error('[!] Error:', response.status, 'returned');
      throw new Error(`Request failed with status ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error('[!] Error:', error);
    throw new Error('An error occurred during API call');
  }
}

export async function changeUserInformation(data) {
  try {
    const response = await serviceAxios.post(`/private/change-user`, data);

    if (response.status !== 200) {
      console.error('[!] Error:', response.status, 'returned');
      throw new Error(`Request failed with status ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error('[!] Error:', error);
    throw new Error('An error occurred during API call');
  }
}
export async function postNewUserImage(data) {
  try {
    const formData = new FormData();

    formData.append('file', data.file);
    formData.append('imageId', data.videoId);
    const response = await serviceAxios.post(
      '/private/set-user-image',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );



    return response.data;
  } catch (error) {
    console.error('An error occurred:', error);
    return 'error';
  }
}
export async function postChatMessage(data) {
  try {
    const response = await await serviceAxios.post(
      '/private/start-chat',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
}
