import React, { useEffect, useState, useRef } from 'react';
import threePointsIcon from '../../../images/three-points-icon.png';
import ContextMenuVideo from './ContextMenuVideo';
import { vocabulary } from '../../../services/vocabulary';
export default function VideoCardItem({
  video,
  onLoadGet,
  onClickGetShow,
  onChangeVideoName,
  setVideoForDelete,
  lang,
  chooseVideo,
}) {
  const [blobUrl, setBlobURL] = useState(null);
  const [showContext, setShowContext] = useState(false);
  const targetElementRef = useRef(null);
  const onClickDelete = async () => {
    setVideoForDelete(video._id);
    setShowContext(false);
  };

  let blob;

  const onClickShow = () => {
    onClickGetShow(
      video._id,
      blobUrl,
      video.videoDuration,
      video.videoName,
      video.sessionsQuantity
    );

    onLoadGet();
  };

  useEffect(() => {
    if (video.fileImage) {
      let bufferObject = JSON.parse(video.fileImage);
      const uint8Array = new Uint8Array(bufferObject.data);
      blob = new Blob([uint8Array], { type: bufferObject.type });
      setBlobURL(URL.createObjectURL(blob));
    }
  }, [video]);

  const onClickContextMenu = (e) => {
    e.stopPropagation();
    setShowContext(!showContext);

    if (!showContext) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
  };

  const onClickRename = () => {
    onChangeVideoName(video._id);
  };

  function handleClickOutside(event) {
    if (
      targetElementRef.current &&
      !targetElementRef.current.contains(event.target)
    ) {
      document.removeEventListener('click', handleClickOutside);
      setShowContext(false);
    }
  }

  return (
    <div key={video._id} className="videos-item">
      <div className="image-preview-div" onClick={onClickShow}>
        {blobUrl ? (
          <img
            className="image-preview-video"
            src={blobUrl}
            alt={`Image preview`}
          />
        ) : null}
      </div>
      <div className="video-text-block-above" ref={targetElementRef}>
        <div className="video-info" onClick={onClickShow}>
          <div className="video-name">
            {video.videoName.toUpperCase().substring(0, 27)}
          </div>
          <div className="show-quantity">
            {vocabulary.sessions[lang]} :&nbsp;{video.sessionsQuantity}
          </div>
        </div>
        <div className="three-points" ref={targetElementRef}>
          <img
            src={threePointsIcon}
            alt="three-points-icon"
            className="three-points-icon"
            onClick={onClickContextMenu}
          />
          {showContext && (
            <ContextMenuVideo
              onClickShow={onClickShow}
              onClickDelete={onClickDelete}
              onClickRename={onClickRename}
              lang={lang}
              video={video}
              chooseVideo={chooseVideo}
            />
          )}
        </div>
      </div>
    </div>
  );
}
