import axios from 'axios';

const serviceAxios = axios.create({
  baseURL: `${('base url', process.env.REACT_APP_SERVER_API_URL)}`,
});

//enviar el token en cada request que haga el usuario para checkear
serviceAxios.interceptors.request.use((config) => {
  //buscar el token en el localStorage
  const storedToken = localStorage.getItem('authToken');
  //si el token existe lo añadimos a los headers del request //pasar la autorizacion como string  indicando el tipo de autenticacion. OJO CON LOS ESPACIOS!!
  config.headers = storedToken && { Authorization: `Bearer ${storedToken}` };

  return config;
});

export default serviceAxios;
