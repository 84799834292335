import React, { useEffect, useState } from 'react';
import { changeVideoName } from '../../../api/workFlowApi';
import closeIcon from '../../../images/close-icon.png';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { vocabulary } from '../../../services/vocabulary';
export default function RenameVideo({
  onLoadGet,
  onClickCloseChangeVideo,
  videoId,
  lang,
}) {
  const [videoName, setVideoName] = useState('');

  const handleNameChange = (e) => {
    setVideoName(e.target.value);
  };
  const handleCloseClick = () => {
    onClickCloseChangeVideo();
  };
  const handleChangeSubmit = () => {
    changeVideoName({ videoId: videoId, videoName: videoName });
    onClickCloseChangeVideo();
    onLoadGet();
  };

  return (
    <div className="new-video-modal">
      <div className="new-video-form">
        <div className="header-video">
          <div className="text-inside-div-header-new-video">
            {vocabulary.renameMovieHeader[lang]}
          </div>
          <div
            className="new-video-add-close-icon"
            onClick={onClickCloseChangeVideo}
          >
            <img src={closeIcon} alt="Movies icon" className="menu-icons" />
          </div>
        </div>
        <div className="download-element-input">
          <label className="label-input-text" htmlFor="name">
            {vocabulary.movieName[lang]}
          </label>

          <div className="input-element-name">
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              name="showName"
              onChange={handleNameChange}
              value={videoName}
              sx={{
                background: 'white',
                borderRadius: '10px',
                input: {
                  color: 'gray',

                  '& ~ .MuiInputAdornment-root': {
                    backgroundColor: '#fff',
                  },
                },
              }}
            />
          </div>
        </div>
        <div>
          <div className="input-element">
            <label htmlFor="file"></label>
          </div>
          <div className="input-element"></div>
        </div>

        <div className="new-show-line-change-name" />
        <div className="button-block">
          <div className="time-date-set-text">
            <Button
              variant="outlined"
              color="primary"
              style={{
                width: '100%',
                height: '50px',
                marginTop: '20px',
                borderColor: '#9e9e9e',
                color: '#0D1C28',
              }}
              fullWidth
              margin="normal"
              onClick={handleCloseClick}
            >
              {vocabulary.cancel[lang]}
            </Button>
          </div>
          <div className="time-date-set-text">
            <Button
              variant="outlined"
              color="primary"
              style={{
                width: '100%',
                height: '50px',
                marginTop: '20px',
                backgroundColor: 'black',
                color: 'white',
              }}
              fullWidth
              margin="normal"
              onClick={handleChangeSubmit}
            >
              {vocabulary.renameMovieHeader[lang]}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
