import React, { useEffect, useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import QRCode from 'react-qr-code';
import closeIcon from '../../../images/close-icon.png';
import { vocabulary } from '../../../services/vocabulary';

export default function QrGenerator({ showId, closeQrModal, lang }) {
  const baseUrl = process.env.REACT_APP_CLIENT_URL;
  const qrCode = baseUrl + '/?id=' + showId;
  const elementToCapture = useRef(null);

  const handleClickInsideNewVideo = (e) => {
    e.stopPropagation();
  };
  const handleCloseClick = () => {
    closeQrModal();
  };

  const captureElementAsImage = () => {
    if (elementToCapture.current) {
      html2canvas(elementToCapture.current).then((canvas) => {
        const image = canvas.toDataURL('image/png');
    
        const download = document.createElement('a');
        download.href = image;
        download.download = 'generated_image.png';
        download.click();
      });
    }
  };

  const shareViaEmail = () => {
    const emailSubject = vocabulary.linkToJoin[lang];
    const emailBody =
      emailSubject + ':\n' + qrCode + '\n' + vocabulary.thanks[lang];
    const emailUrl = `mailto:?subject=${encodeURIComponent(
      emailSubject
    )}&body=${encodeURIComponent(emailBody)}`;
    window.location.href = emailUrl;
  };

  return (
    <div className="qr-code-modal" onClick={handleClickInsideNewVideo}>
      <div className="header-video">
        <div className="text-inside-div-header-new-video"></div>
        <div className="new-video-add-close-icon" onClick={handleCloseClick}>
          <img src={closeIcon} alt="Movies icon" className="menu-icons" />
        </div>
      </div>

      <div className="qr-code-main">
        <div className="link-generated">
          {' '}
          <a
            href={qrCode}
            style={{ textDecoration: 'none', color: 'white' }}
            target="_blank"
          >
            {qrCode}
          </a>
        </div>
        <div className="code-qr" ref={elementToCapture}>
          <QRCode value={qrCode} size={286} />
        </div>
        <div className="button-qr-div">
          <button className="buttons-qr" onClick={captureElementAsImage}>
            {vocabulary.download[lang]}
          </button>
          <button className="buttons-qr" onClick={shareViaEmail}>
            {vocabulary.qRSend[lang]}
          </button>
        </div>
      </div>
    </div>
  );
}
