import penIcon from '../../../images/pen-icon.png';
import eraserIcon from '../../../images/eraser-icon.png';
import qrIcon from '../../../images/qr-icon.png';
import enterIcon from '../../../images/enter-icon.png';
import { vocabulary } from '../../../services/vocabulary';

export default function ContextMenuShow({
  isEnded,
  onClickStart,
  onclickStats,
  onClickChange,
  onClickDelete,
  handleShowQR,
  lang,
}) {
  return (
    <div className={!isEnded ? 'context-menu-div' : 'context-menu-div-ended'}>
      <div
        className="context-menu-center-text"
        onClick={!isEnded ? onClickStart : onclickStats}
      >
        <div className="icon-submenu">
          <img src={enterIcon} alt="Enter icon" className="menu-icons" />
        </div>

        {isEnded
          ? vocabulary.showStatistics[lang]
          : vocabulary.startSession[lang]}
      </div>
      {} <div className="line-div" />
      {!isEnded && (
        <>
          <div className="context-menu-center-text" onClick={handleShowQR}>
            <div className="icon-submenu">
              <img src={qrIcon} alt="Qr icon" className="menu-icons" />
            </div>
            {vocabulary.showQR[lang]}
          </div>
          <div className="line-div" />
        </>
      )}
      {!isEnded && (
        <>
          <div className="context-menu-center-text" onClick={onClickChange}>
            <div className="icon-submenu">
              <img src={penIcon} alt="change icon" className="menu-icons" />
            </div>
            {vocabulary.changeSession[lang]}
          </div>
          <div className="line-div" />
        </>
      )}
      <div className="context-menu-center-text" onClick={onClickDelete}>
        <div className="icon-submenu">
          <img src={eraserIcon} alt="Eraser icon" className="menu-icons" />
        </div>
        {vocabulary.deleteSession[lang]}
      </div>
    </div>
  );
}
