import { Routes, Route, BrowserRouter } from 'react-router-dom';

import Login from './Pages/PublicPages/LoginScreen';
import SignUp from './Pages/PublicPages/SignUpScreen';
import UserMenu from './Pages/AuthPages/UserScreen';
import GlobalContext from './GlobalContext';
import { useContext } from 'react';
import VideoScreen from './Pages/AuthPages/VideosScreen';
import ShowsScreen from './Pages/AuthPages/ShowsScreen';
import PlayerScreen from './Pages/AuthPages/PlayerScreen';
import ShowEndedScreen from './Pages/AuthPages/ShowEndedScreen';
import PasswordRecoveryScreen from './Pages/PublicPages/PasswordRecoveryScreen';
import EmailSent from './Pages/PublicPages/EmailSent';
import CodeConfirm from './Pages/PublicPages/CodeConfirmation';
import VideoDetailsScreen from './Pages/AuthPages/VideoDetailsScreen';
import SplashScreen from './Pages/PublicPages/SplashScreen';

export default function RoutesArtefacto() {
  const { isLoggedIn,isLoading } = useContext(GlobalContext);


  return (
    <BrowserRouter>
      <Routes>

        {isLoading? <Route path="*" element={<SplashScreen/>} />:isLoggedIn ? (
          <>
            <Route path="/" element={<VideoScreen />} />
            <Route path="/shows-list" element={<ShowsScreen />} />
            <Route path="/player" element={<PlayerScreen />} />
            <Route path="/main" element={<VideoScreen />} />
            <Route path="/video-details" element={<VideoDetailsScreen />} />
            <Route path="/user-menu" element={<UserMenu />} />
            <Route path="/show-ended" element={<ShowEndedScreen />} />
            <Route path="*" element={<VideoScreen />} />
          </>
        ) : (
          <>
            <Route path="/email-sent" element={<EmailSent />} />
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/activate" element={<CodeConfirm />} />
            <Route path="/recovery" element={<PasswordRecoveryScreen />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}
