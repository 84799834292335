import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from '../../GlobalContext';
import { useNavigate } from 'react-router-dom';
import logoGoogle from '../../images/google-logo.png';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import GoogleAuth from './GoogleAuth';
import logoIcon from './../../images/logo.png';
import footerLogo from './../../images/footer-logo.png';
import { vocabulary } from '../../services/vocabulary';

function Login() {
  const { isLoggedIn } = useContext(GlobalContext);
  const [email, setEmail] = useState('');
  const { state, GlobalActions, langSelected } = useContext(GlobalContext);
  const [showLoginError, setShowLoginError] = useState(false);
  const [password, setUserPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [googleError, setGoogleError] = useState(false);
  const navigate = useNavigate();
  const [lang, setLang] = useState(0);

  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (state.userToken) {
  

      navigate('/');
    }
  }, [state]);

  useEffect(() => {}, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGoogleError(false);
    setShowLoginError(false);

    let res = await GlobalActions.logIn({
      email: email,
      password: password,
    });

    if (res === 'bad credentials') {
      setShowLoginError(true);
    }
  };

  const handleOnClickSignUp = () => {
    navigate('/signup');
  };
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSetShowLogInError = () => {
    setGoogleError(true);
  };

  return (
    <div className="login-screen">
      <img src={footerLogo} alt="Logos"style={{width: "57%" ,position:"absolute",bottom:'0',zIndex:2,paddingLeft:"1%",backgroundColor:'#ffffffd1'}} />
      <div className="login-left-part">
        <div className="logo-group-login">
          <img src={logoIcon} alt="Movies icon"  height={"150px"} width={"200px"}/>
        </div>

        <div className="tapper"></div>
      </div>

      <div className="login-container">
        <div className="login-box">
          <form className="form-login" onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="input-div-login-text">
                <div className="login-header">
                  {vocabulary.startSession[lang]}
                </div>
              </div>
              <label htmlFor="e-mail"></label>
              <div className="input-div">
                <TextField
                  variant="outlined"
                  autoComplete="username"
                  fullWidth
                  margin="normal"
                  placeholder={vocabulary.userName[lang]}
                  name="userName"
                  value={email}
                  onChange={(e) => {
                    setShowLoginError(false);

                    setEmail(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <label htmlFor="password"></label>
              <div className="input-div">
                <TextField
                  variant="outlined"
                  style={{ color: 'white' }}
                  fullWidth
                  margin="normal"
                  placeholder={vocabulary.password[lang]}
                  name="password"
                  value={password}
                  autoComplete="current-password"
                  onChange={(e) => {
                    setShowLoginError(false);
                    setUserPassword(e.target.value);
                  }}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="input-div">
                <div
                  className="forgot-my-password"
                  onClick={() => {
                    navigate('/recovery');
                  }}
                >
                  {vocabulary.passwordForgotten[lang]}
                </div>

                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    width: '100%',
                    height: '50px',
                    marginTop: '20px',
                    backgroundColor: '#0D1C28',
                    color: 'white',
                  }}
                  fullWidth
                  margin="normal"
                  onClick={handleSubmit}
                >
                  {vocabulary.enter[lang]}
                </Button>
              </div>
              <div className="input-div">
                <div
                  className="not-have-an-account"
                  onClick={handleOnClickSignUp}
                >
                  {vocabulary.doNotHaveAccount[lang]}
                </div>
              </div>
              <GoogleAuth
                login={true}
                setShowLogInError={handleSetShowLogInError}
              />{' '}
              <div className="input-div">
                {googleError && (
                  <div className="error-message">
                    {' '}
                    {vocabulary.doNotRegistered[lang]}
                  </div>
                )}
                {showLoginError && (
                  <div className="error-message">
                    {vocabulary.errorMailPassword[lang]}
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
