import React, { useEffect, useState } from 'react';
import closeIcon from '../../../images/close-icon.png';
import warningTriangle from '../../../images/warning-triangle.png';
import { deleteVideo } from '../../../api/workFlowApi';
import Button from '@mui/material/Button';
import { vocabulary } from '../../../services/vocabulary';

export default function DeleteVideo({
  onClickCloseDeleteVideo,
  onLoadGet,
  id,
  lang,
}) {
  const handleCloseDeleteClick = () => {
    onClickCloseDeleteVideo();
  };
  const handleDeleteSubmit = async () => {
    await deleteVideo({ video: id });
    onLoadGet();
    onClickCloseDeleteVideo(id);
  };

  return (
    <div className="new-video-modal">
      <div className="delete-video-form">
        <div className="header-delete-video">
          <div className="text-inside-div-header-delete-video">
            {vocabulary.deleteVideoHeader[lang]}
          </div>
          <div
            className="new-video-add-close-icon"
            onClick={handleCloseDeleteClick}
          >
            <img src={closeIcon} alt="Movies icon" className="menu-icons" />
          </div>
        </div>
        <div className="delete-element-text">
          <img
            src={warningTriangle}
            alt="warning icon"
            width={'88px'}
            height={'76px'}
          />
          <h4> {vocabulary.deleteVideo[lang]} </h4>
        </div>

        <div className="delete-line" />
        <div className="button-block-delete">
          <div className="time-date-set-text">
            <Button
              variant="outlined"
              color="primary"
              style={{
                width: '100%',
                height: '50px',
                marginTop: '20px',
                borderColor: '#9e9e9e',
                color: '#0D1C28',
              }}
              fullWidth
              margin="normal"
              onClick={handleCloseDeleteClick}
            >
              {vocabulary.cancel[lang]}
            </Button>
          </div>

          <div className="time-date-set-text">
            <Button
              variant="outlined"
              color="primary"
              style={{
                width: '100%',
                height: '50px',
                marginTop: '20px',
                backgroundColor: 'black',
                color: 'white',
              }}
              fullWidth
              margin="normal"
              onClick={handleDeleteSubmit}
            >
              {vocabulary.erase[lang]}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
