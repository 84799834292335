export default function formatTime(durationInMs) {
  const milliseconds = durationInMs % 1000;
  const seconds = Math.floor((durationInMs / 1000) % 60);
  const minutes = Math.floor((durationInMs / (1000 * 60)) % 60);
  const hours = Math.floor(durationInMs / (1000 * 60 * 60));

  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${milliseconds
    .toString()
    .padStart(3, '0')}`;
}
